export default {
	'upcomingAppointments': 'Appointments',
	'addAppointments': 'Add Appointment',
	'intakes': 'Evaluations',
	'addIntake': 'Add Intake',
	'forSelf': 'Appointment For Self',
	'addPatient': 'Add Patient',
	'editPatient': 'Edit Patient',
	'welcomeBack': 'Welcome',
	'noAppointments': 'No Appointments',
	'allRightsReserverd': 'All Rights Reserved',
	'privacyPolicy': 'Privacy Policy',
	'terms&&conditions': 'Terms & Conditions',
	'helpMessage': 'If this is an emergency, call 911. If you are thinking about harming yourself or others and/or are experiencing a crisis, please call the crisis line available 24 hours a day at 1-800-534-4673.',
	'helpMessage2': 'Please feel free to call 602-888-8882 at any time.',
	'invoices': 'Invoices',
	'appointments': 'Appointments',
	'patients': 'Patients',
	'messages': 'Messages',
	'myTasks': 'My Tasks',
	'bills': 'Bills',
	'myRecords': 'My Records',
	'myLibrary': 'My Library',
	'myMembership': 'My Membership',
	'patientInvoices': 'Patient Invoices',
	'noInvoices': 'No Invoices',
	'name': 'Name',
	'next': 'Next',
	'back': 'Back',
	'select': 'Please select',
	'selectOnly': 'Select',
	'explainFurther': 'Please explain further',
	'explainFurtherPlease': 'Please explain further',
	'formContinueOrStartOver': 'This patient already has an Intake Questionnaire in progress',
	'formContinueOrStartOver1': 'To discard the current Intake Questionnaire and begin a new one, click on "Add New Intake".',
	'formContinueOrStartOver2': 'To continue working on the current Intake Questionnaire, click on "Continue".',
	'startOver': 'Add New Intake',
	'continue': 'Continue',
	'test': 'works',
	'undo': 'Undo',
	'yes': 'Yes',
	'no': 'No',
	'view': 'View',
	'dateTime': 'Date/Time',
	'doctor': 'Doctor',
	'paymentStatus': 'Payment Status',
	'status': 'Status',
	'reschedule': 'RESCHEDULE',
	'cancelAppointment': 'CANCEL APPOINTMENT',
	'cancelled': 'CANCELLED',
	'fieldRequired': 'The above field is required',
	'phoneInvalid': 'Phone is invalid',
	'selectAtLeastOne': 'Please select at least one checkbox',
	'selectOne': 'Please select one of these',
	'signup_continue': 'Continue',
	// welcome-back
	'welcomeBack': 'Welcome Back!',
	'yourIntakeQues': 'Your Intake Questionnaire for',
	'is': 'is',
	'complete': 'complete',
	'toContinue': 'To continue completing your intake questionnaire for',
	'pleaseClickContinueIntake': 'please click the "Continue Intake" button above.',
	'pleaseHaveAReady': 'Please have ready any of the following documents that applies to the patient, you will be asked to upload images:',
	'continueIntake': 'Continue Intake',
	'insuranceCards': 'Insurance Card(s)',
	'courtOrderOrOther': 'Court orders or other legal documentation regarding legal decision making, custody arrangements, temporary placement, adoption, guardianship, etc.',
	'schoolDocs': 'School documents regarding an IEP, 504 plan, special education, gifted programming, etc.',
	'anyPrevEval': 'Any previous evaluations or psychological testing results',
	// new-intake
	'thankyouForChoosingAxis': 'Thank you for choosing Axis for Autism! We look forward to supporting you during this important testing process.',
	'beginIntakeProcess': 'To begin the intake process',
	'pleaseClickStartIntake': 'please click the “Start Intake” button below. The sooner you can complete the intake the faster we can schedule your appointment and complete the diagnosis process. The questionnaire may take 45-60 minutes to complete. You are able stop at any time and complete the intake later without losing your progress.',
	'startIntake': 'Start Intake',
	'contactUsMessage': 'If you have any questions or concerns, please contact us at (602) 888-8882 or email us at',
	// thank-you
	'thankyouFotIntake': 'Thank you for completing our Intake Questionnaire.',
	'whatsNext': 'What’s next?',
	'careCordinatorWillContact': 'One of our Patient Care Coordinators will contact you within 7 business days to schedule an Assessment. The length of the Assessment appointment can last up to 2 hours. Please plan accordingly',
	'careCordinatorWillDiscuss': 'The Patient Care Coordinator will discuss any further required information for the Assessment with you during the scheduling process.',
	'intakeForAnotherPatient': 'Do you need to complete an intake for another patient?',
	'ifYes': 'If YES, please select the “Start Intake” button below.',

	// ADD-NEW --> AppointmentType.vue
	'appointmentType': 'Which appointment would you like?',
	'DAE': 'Diagnostic Autism Evaluation',
	'PM': 'Psychiatry/Medication Management',
	'consent': 'I understand this information will be shared with Axis for Autism, LLC. I have the authority to share this information',
	'read': 'Please read and agree to the following terms:',
	'allRequiredFields': 'All * fields are required',

	// FORM-HOME --> /patients/web/src/pages/intake/completingForm/Home.vue
	'who': 'Who is filling out this form?',
	'optionSelect': 'Select your option',
	'legal': 'Legal Guardian/Parent of minor patient',
	'guardian': 'guardian/parent',
	'adult1': 'Adult patient for self',
	'adult2': 'Guardian/POA or legally authorized person for adult patient',
	'dcsRep': 'DCS Representative/Foster Parent/Kinship Guardian',
	'other': 'Other',
	'referal': 'How did you hear about Axis for Autism?',
	'evaluationGoals': 'Please explain why you want the patient to be evaluated; include your hopes and goals for the evaluation.',
	'school_Prof_grades_k12': 'School Professional (Grades K-12)',
	'preschool': 'Preschool or Daycare',
	'primary_care': 'Primary Care Physician or Specialist',
	'mental_health': 'Mental Health Provider (Psychiatrist, Counselor, Psychologist)',
	'social': 'Social Media',
	'friend_family': 'Friend or Family Member',
	'school_name': 'School Name',
	'professionals_name': 'Professional’s Name',
	'preschool_name': 'Preschool or Daycare’s Name',
	'office_name': 'Office Name',
	'doctor_name': `Doctor's Name`,
	'provider_name': `Provider's Name`,
	'facebook': 'Facebook',
	'instagram': 'Instagram',
	'please_specify': 'Please Specify',

	// form-demographic ---> Demographic.vue
	'demographicInfo': 'Demographic Information',
	'fullName': 'Full name of the person completing this form',
	'patientRelation': 'Relationship to Patient',
	'email': 'Email Address',
	'phone': 'Phone Number',
	'patientDateOfBirth': "Patient's Date of Birth",
	'prefLang': 'Preferred Language',
	'pleaseSpecifyLang': 'Please specify the language',
	'some_high_school': 'Some High School',
	'high_school_graduate_ged': 'High School Graduate/GED',
	'some_college': 'Some College',
	'college_graduate': 'College Graduate',
	'levelOfEdu': 'Level of education',
	'whoIsEmp': 'Who is your employer?',

	// special-needs-verification SpecialNeed.vue , helpMessage included, //special-needs-page

	'specialNeed': 'Special Accommodations',
	'doesThePatientEngage': 'Does the patient engage in any behavior that may risk the physical safety of themselves or others? (Examples: self-injurious behaviors like head banging or biting, property destruction, elopement, verbal and/or physical aggression, etc.)',
	'specialNeedMessage': 'Please select “yes” if any of the following apply to the patient:',
	'specialNeedMessage2': 'Please bring items like glasses, hearing aids, walkers, and AAC devices to the appointment if no other accommodations are needed',
	'item0': 'Does this patient require special accommodations?',
	'item1': 'Speaks a language other than English or Spanish',
	'item2': 'Visual Impairment/Blind',
	'item3': 'Hearing Impairment/Deaf',
	'item4': 'Mobility Issues (For example, unable to walk or take steps independently, arm or leg limitations)',
	'item5': 'Uses special communication device (For example, AAC)',
	'consultationMessage': 'One of our Patient Care Coordinators will reach out to you within 48 hours to start the scheduling process.',
	'goToHome': 'Go to home page',
	'specialNeedHelp': 'If this is an emergency, please call 911. If you are thinking about harming yourself or someone else, or if you are currently experiencing a crisis, please call the 24-hour crisis line at 1-800-534-4673.',
	'specialNeedHelp2': 'If you have non-emergent questions, please feel free to contact us at 602-888-8882 during our normal business hours.',
	'pleaseSelect': 'Please select any of the following circumstances that apply to the patient.',

	//form-list completingForm/List.vue

	'listMessage': 'Please list all people living in the same home as the patient, including yourself',
	'firstName': 'First Name',
	'gender': 'Gender',
	'age': 'Age',
	'relation': 'Relation to Patient',
	'addRelation': 'Add Relation',
	'addMedCond16': 'Add Another Diagnosis or Condition',

	//FORM-DEMOGRAPHIC-ADULT --> /patients/web/src/pages/intake/completingForm/Adult.vue

	'adultInfo': 'Adult Patient Being Seen',
	'nameAdult': 'Patient Legal First, Middle, & Last Name',
	'nameAdultFirst': 'Patient\'s Legal First Name',
	'nameAdultMiddle': 'Patient\'s Legal Middle Name',
	'nameAdultLast': 'Patient\'s Legal Last Name',
	'preferredName':'Patient Preferred Name (if different from above)',
	'fullAddress': 'Patient Full Home Address',
	'streetAddress': 'Street Address',
	'city': 'City',
	'zip': 'Zip Code',
	'state': 'State',
	'patientState': "Patient's State",
	'patientCity': "Patient's City",
	'patientZipcode': "Patient's Zip Code",
	'patientStreetAddress': "Patient's Street Address",
	'patientFirstName': "Patient's First Name",
	'patientMiddleName': "Patient's Middle Name",
	'patientLastName': "Patient's Last Name",
	'emailAddress': "Patient's Email Address",
	'genderPatient': "Patient's Gender Assigned at Birth",
	'pronouns': `Patient's Preferred Pronouns (if different from above)`,
	'phonePatient': 'Patient Phone',
	'language': ' Patient Primary Language Spoken / Understood',
	'otherLanguage': 'Other Language(s) Spoken / Understood',
	'withWhom': 'With whom does the patient live?',
	'alone': 'Alone',
	'pa': 'Father',
	'ma': 'Mother',
	'family': 'Family',
	'friend': 'Friend',
	'otherOption': 'Other',
	'pleaseExplain': "If you answered 'Other' to the previous question, please explain further.",
	'wh_cw': 'Work History / Current Work',
	'mh': 'Military History',
	'zipcodeNotCorrespond': "zipcode doesn't correspond to any US state/city",

	//https://seacole.io/patients/appointment/intake/insurance-details
	'insuranceMessage': 'What is your preferred method of payment?',
	'secondaryInsuranceQuestion': '  I have secondary insurance',
	'secondaryInsuranceMessage': 'Please provide your secondary insurance details',
	'chooseInsurance': 'Primary Insurance Provider',
	'secondarychooseInsurance': 'Secondary Insurance Provider',
	'banner': 'Banner Univ.',
	'arizona': 'Arizona Complete Health',
	'test': 'Test (out of network)',
	'gnumber': 'Primary Insurance Group Number',
	'secondarygnumber': 'Secondary Insurance Group Number',
	'pnumber': 'Primary Insurance Policy Number',
	'additionalQuestion': 'Is there any other financial assistance you will be using?',
	'secondarypnumber': 'Secondary Insurance Policy Number',
	'insuranceName': 'Policy Holder’s Name as it appears on the insurance card',
	'otherInsuranceName': "Patient's Other Insurance Details",
	'secondaryInsuranceName': "Policy Holder's First Name as it appears on the secondary insurance card",
	'op1': 'Private Pay',
	'op2': 'Insurance',
	'op3': 'ESA Funds',
	'op4': 'Gentry Scholarship',
	'op5': 'Uninsured',
	'oonh': 'Your insurance is out of network',
	'oond1': 'Unfortunately we are not in-network with your insurance at this time. If you are interested in private pay, the out-of-pocket cost for the evaluation starts at $1750 for children and starts at $2030 for adults.',
	'oond2': 'Some medical expenses are also tax deductible. Please consult with a tax professional for information about this.',
	'oond3': 'If you need help please call our office at 602-888-8882.',
	'uploadesa': 'Upload ESA Fund Records',
	'esa_verbiage': 'Please upload a picture of your ClassWallet balance. ESA Funds can be used for special education students only. An IEP is required to use this option and we will require you to upload a copy on a different page.',
	'uploadgentry': 'Upload Gentry Scholarship Records',
	'gentry_verbiage': 'This applies only to patients that are 0-5 years old. A copy of the scholarship award must be uploaded in order to choose this option.',
	'phFname': 'Policy Holder’s First Name as it appears on the insurance card',
	'phLname': 'Policy Holder’s Last Name as it appears on the insurance card',
	'phDob': 'Policy Holder’s Date of Birth',
	'patientRelationInsured': 'Patient’s Relation to insured',
	'phAddress1': 'Policy Holder’s Address',
	'phAddress2': 'Policy Holder’s Address (e.g., apartment, room, floor)',
	'phGender': 'Policy Holder’s Gender',
	'secondary_ins_verbiage': 'It is important that you provide both your primary and secondary insurance information, even if we are considered out-of-network. Not providing complete information could result in your visits being denied.',
	'uploadAPictureOfInsurance': 'Please upload a picture of the patient’s insurance card.',
	'frontOfCard': 'Front of Card',
	'backOfCard': 'Back of Card',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation1
	'behav1': 'Behavioral & Social History',
	'when1': "When did you start to worry about the patient's development? Please explain",

	//https://seacole.io/patients/appointment/intake/BehavioralInformation2
	'behav2': 'Behavioral & Social History',
	'what2': "What are the patient's communication & speech abilities? For example, how does the patient communicate that they need something, want something, or that something is wrong? Please check all that apply.",
	'fluent': ' Fluent, full sentences, no communication or speech issues',
	'vocal': ' Vocal, babble, makes noises to communicate',
	'points': ' Points with their finger to what they want/need',
	'pulls': ' Pulls others to what they want/need',
	'doesNot': ' Does not indicate they want/need anything at all',
	'usesAsl': ' Uses ASL signs to communicate',
	'grabs': " Grabs others' hand & uses it to point to what they want/need",
	'gestures': ' Gestures with their hands or arms to what they want/need',
	'brings': ' Brings what they want/need to others',
	'single': ' Single words only',
	'non': ' Non-speaking',
	'phrased': ' Phrases only',
	'cries': ' Cries, screams, has tantrum',
	'shutsdown': ' Shuts down, refuses to speak or experiences selective mutism',
	'pleaseFurther': ' Please explain your selection(s) further',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation3
	'behav3': 'Behavioral & Social History',
	'has3': 'Has the patient ever had any difficulties with toileting?',
	'n3': 'Never',
	'p3': 'Past',
	'pt3': 'Present',
	'answer3': "If you answered 'Past' or 'Present', please explain further.",

	//https://seacole.io/patients/appointment/intake/BehavioralInformation4
	'behav4': 'Behavioral & Social History',
	'has4': 'Has the patient ever had any difficulties with eating, including any special diets or food allergies?',
	'n4': 'Never',
	'p4': 'Past',
	'pt4': 'Present',
	'answer4': 'If you answered ‘Past’ or ‘Present’, please explain further.',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation5
	'behav5': 'Behavioral & Social History',
	'has5': 'Has the patient ever had any difficulties with sleeping?',
	'n5': 'Never',
	'p5': 'Past',
	'pt5': 'Present',
	'answer5': "If you answered 'Past' or 'Present', please explain further.",

	//https://seacole.io/patients/appointment/intake/BehavioralInformation6
	'behav6': 'Behavioral & Social History',
	'please6': 'Please select any of the following behavioral characteristics that apply to the patient:',
	'a6': ' Aggressive',
	'an6': ' Angry',
	'w6': ' Withdrawn',
	's6': ' Struggles with substance abuse',
	'o6': ' Overly active',
	'd6': ' Distractible',
	'i6': ' Impulsive',
	'sd6': ' Sad or depressed',
	'l6': ' Low self-esteem',
	'sy6': ' Shy',
	'p6': ' Perfectionist',
	'f6': ' Friendly',
	'fr6': ' Fearful',
	'fg6': ' Forgetful',
	'c6': ' Cries a lot',
	'sa6': ' Short attention span',
	'wo6': ' Worries a lot',
	'n6': ' Noncompliant',
	'h6': ' Has contact with police',
	'ha6': ' Happy',
	'no6': ' None of these',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation7
	'behav7': 'Behavioral & Social History',
	'does7': 'Does the patient socialize & interact (or play) well with their peers?',
	'y7': 'Yes',
	'n7': 'No',
	'answer7': "Please explain further & describe what the patient's social behavior looks like",

	//https://seacole.io/patients/appointment/intake/BehavioralInformation8
	'behav8': 'Behavioral & Social History',
	'does8': 'Does the patient have friends?',
	'y8': 'Yes',
	'n8': 'No',
	'answer8': "Please explain further & if you answered 'Yes', please also describe how they met & what their interactions look like",

	//https://seacole.io/patients/appointment/intake/BehavioralInformation9
	'behav9': 'Behavioral & Social History',
	'does9': 'Does the patient belong to any groups, clubs, or teams?',
	'y9': 'Yes',
	'n9': 'No',
	'explanation9': 'Please explain.',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation10
	'behav10': 'Behavioral & Social History',
	'i10': 'Is the patient frightened by any of the following:',
	'a10': ' Animals',
	'r10': ' Rough Housing',
	'l10': ' Loud Noises',
	't10': ' The Dark',
	'o10': ' Other',
	'n10': ' None of these',
	'answer10': 'Please explain.',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation11
	'behav11': 'Behavioral & Social History',
	'does11': 'Does the patient seek out taste, touch, sight, smell, or sound in any unusual ways?',
	'y11': 'Yes',
	'n11': 'No',
	'explanation11': 'Please explain further',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation12
	'behav12': 'Behavioral & Social History',
	'is12': 'Is the patient bothered by any tastes, touch, sights, smells, or sounds?',
	'y12': 'Yes',
	'n12': 'No',
	'explanation12': 'Please explain further',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation13
	'behav13': 'Behavioral & Social History',
	'does13': 'Does the patient overreact or underreact to pain?',
	'y13': 'Yes',
	'n13': 'No',
	'explanation13': 'Please explain further',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation14
	'behav14': 'Behavioral & Social History',
	'does14': 'Does the patient have any preoccupations, rituals, or obsessions?',
	'y14': 'Yes',
	'n14': 'No',
	'explanation14': 'Please explain further',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation15
	'behav15': 'Behavioral & Social History',
	'does15': 'Does the patient say or do anything over and over again? This includes activities, body movements, sounds, etc',
	'y15': 'Yes',
	'n15': 'No',
	'explanation15': 'Please explain further',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation16
	'behav16': 'Behavioral & Social History',
	'does16': 'Does the patient have any unusual behaviors?',
	'y16': 'Yes',
	'n16': 'No',
	'explanation16': 'Please explain further',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation17
	'behav17': 'Behavioral & Social History',
	'please17': 'Do you have any other concerns that you would like to share?',
	'otherConcerns17': 'What other concerns do you have?',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation18
	'behav18': 'Behavioral & Social History',
	'p18': "Please describe the patient's interests and how they like to spend their time",
	'pd18': 'How does the patient like to spend their time?',
	'pdp18': 'Please describe the patient’s strengths',
	'pdpf18': "Please describe the patient's family's and/or support system's strengths",
	'pdpft18': 'Is there anything else you think is important for us to know?',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation19
	'behav19': 'Behavioral & Social History',
	'does19': 'Is the patient a danger to themselves or a danger to others?',
	's19': 'Self',
	'o19': 'Others',
	'n19': 'None',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation20
	'behav20': 'Behavioral & Social History',
	'does20': 'Has the patient ever been arrested?',
	'y20': 'Yes',
	'n20': 'No',
	'explanation20': 'Please explain further:',

	//https://seacole.io/patients/appointment/intake/Behaviour1
	'behaviour': 'Behaviour',
	'childHadAnyPrevPsycEvaluations': 'Has child had any previous Psychological - Psychiatric Evaluations?',
	'childStrengths': 'What are the child&apos;s strengths?',
	'familyStrengths': 'What are the family&apos;s strengths?',
	'behavioralCharacteristics': 'Please select any behavioral characteristics that apply to this child',
	'childPlayWell': 'Does child play well & socialize with other children?',
	'pleaseExplain': 'Please explain',
	'doesSheHaveFriends': 'Does she/he have friends?',
	'howDidTheyMeet': 'If they do have friends, how did they meet? What are their interactions like? If not, please explain.',
	'childBelongToAnyGroups': 'Does child belong to any groups, clubs or teams?',
	'childInterests': 'What are the child&apos;s interests?',
	'favToys': 'Favorite toys or activities',
	'anyRituals': 'Any preoccupations, rituals, obsessions?',

	//https://seacole.io/patients/appointment/intake/Behaviour2
	'childSeekOut': 'Does your child seek out touch, sight, sound, smell or taste in an unusual way?',
	'childBotheredByTouch': 'Is your child bothered by any other touch, sight, sound, smell or taste sensations?',
	'anyRepetitiveBehaviors': 'Describe any repetitive behaviors.',
	'anyUnusualBehaviors': 'Describe any unusual behaviors.',
	'childFrightenedBy': 'Is child frightened by',
	'childOverreact': 'Does your child overreact or underreact to pain?',
	'otherBehaviourProblems': 'Any other behavioral problems or concern?',

	//https://seacole.io/patients/appointment/intake/BSH2
	'behavioralHistory': 'Behavioral & Social History',
	'patientCommunicationAbilities': 'What are the patient&apos;s communication & speech abilities? For example, how does the patient communicate that they need something, want something, or that something is wrong. Please check all that apply.',
	'speechIssues': 'Fluent, full sentences, no communication or speech issues',
	'phrasedSpeechOnly': 'Phrased-speech only',
	'singleWordsOnly': 'Single words only',
	'nonSpeaking': 'Non-speaking',
	'vocalBableMakeNoises': 'Vocal, babble, makes noises to communicate',
	'usesSignsToCommunicate': 'Uses ASL signs to communicate',
	'pointsWithFinger': 'Points with their finger to what they want/need',
	'gestureWithHands': 'Gestures with their hands or arms to what they want/need',
	'grabsOthersHand': 'Grabs others hand & uses it to point to what they want/need',
	'pullsOthersByHand': 'Pulls others by hand or clothing, or pushes them to what they want/need',
	'bringsWhatTheyWant': 'Brings what they want/need to others',
	'criesHasTantrum': 'Cries, screams, has tantrum',
	'doesNotIndicate': 'Does not indicate they want/need anything at all',
	'explainYourSelection': 'Please explain your selections further',

	//https://seacole.io/patients/appointment/intake/bsh/home
	'whenDidYouStartToWorry': 'When did you start to worry about the patient&apos;s development? Please Explain',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation1
	'physio1': 'Developmental History',
	'did1': "Did the patient's birth mother use any of the following while she was pregnant with the patient?",
	'alc1': ' Alcohol',
	'illi1': ' Illicit Substances',
	'medi1': ' Medications',
	'toba1': ' Tobacco',
	'none1': ' None of these',
	'despi1': ' Please explain',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation2
	'physio2': 'Developmental History',
	'lp2': 'How long was the pregnancy (in weeks or months)?',
	'pbw2': 'Patient birth weight in pounds',
	'pbl2': 'Patient birth length in inches',
	'tob2': 'Type of birth?',
	'vag2': 'Vaginal',
	'ces2': 'Cesarean',
	'weret2': 'Were there any prenatal or birthing complications that you are aware of?',
	'yp2': 'Yes',
	'np2': 'No',
	'uk2': 'Unknown',
	'pexplanation2': 'Please explain further',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation3
	'physio3': 'Developmental History',
	'awad3': 'At what age did the patient first...',
	'sa3': 'Sit alone?',
	'sta3': 'Stand alone?',
	'wa3': 'Walk alone?',
	'stfw3': 'Say their first words?',
	'fctw3': 'First combine two words?',
	'fuws3': 'First use 3-4 word sentences?',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation4
	'physio4': 'Developmental History',
	'plaopp4': 'Please list all of the patient’s physicians',
	's4': '',
	'tod4': 'Type of Provider',
	'name4': ' Provider’s Full Name  ',
	'aocs4': 'Address or Cross Streets',
	'office4': 'Name of Office',
	'pon4': 'Phone Number',
	'addMore4': 'Add Another Provider',
	'primary_care_provider': 'Primary Care Provider',
	'providerNameReq': 'Provider full name is required',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation5
	'physio5': 'Developmental History',
	'hpeh5': 'Has the patient ever had any psychological or psychiatric evaluations before?',
	'yp5': 'Yes',
	'np5': 'No',
	'evaluation5': 'When, what was the evaluation for',
	'date5': 'Year of evaluation',
	'reason5': 'Reason for evaluation',
	'doctorname5': 'Office Name',
	'provider5': 'Provider’s Name',
	'disorder5': 'Diagnosis',
	'report5': 'Report',
	'addAnother5': 'Add Another Evaluation',
	'remove': 'Remove',
	'action5': 'Action',
	'yoe_rfe_required': 'The year of evaluation and reason for evaluation fields are required',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation6
	'physio6': 'Developmental History',
	'hpeh6': 'Has the patient ever been tested for chromosomal abnormalities?',
	'yp6': 'Yes',
	'np6': 'No',
	'pexplanation6': 'Please explain further',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation7
	'physio7': 'Developmental History',
	'hpeh7': 'Has the patient had any other medical diagnosis?',
	'yp7': 'Yes',
	'np7': 'No',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation8
	'physio8': 'Developmental History',
	'hpeh8': 'Has the patient ever received any services or therapies?',
	'yp8': 'Yes',
	'np8': 'No',
	'facultyName': 'Office Name',
	'frequency': 'Frequency',
	'typeOfService': 'Type Of Service',
	'addMore8': 'Add Another Service',
	'pastpresent': 'Past/Present',
	'past': 'Past',
	'present': 'Present',
	'report': 'Report',
	'allTableFieldsRequired': 'All table fields are required',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation9
	'physio9': 'Developmental History',
	'hpeh9': 'Has the patient ever had any serious head injuries or accidents?',
	'yp9': 'Yes',
	'np9': 'No',
	'explainP9': 'Please explain further including when and what occurred',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation10
	'physio10': 'Developmental History',
	'hpeh10': 'Has the patient ever had any other hospitalizations or serious illnesses?',
	'yp10': 'Yes',
	'np10': 'No',
	'explainP10': 'Please explain further including when and what occurred',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation11
	'physio11': 'Developmental History',
	'hpeh11': 'Has the patient ever experienced any trauma, abuse, or neglect?',
	'yp11': 'Yes',
	'np11': 'No',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation12
	'physio12': 'Developmental History',
	'hpeh12': 'Does the patient currently have any vision problems &/or use glasses or contacts?',
	'yp12': 'Yes',
	'np12': 'No',
	'lb12': 'Please be sure that the patient brings/wears glasses or contacts to any appointment(s)',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation13
	'physio13': 'Developmental History',
	'hpeh13': 'Does the patient currently have any hearing problems &/or use hearing aids?',
	'yp13': 'Yes',
	'np13': 'No',
	'lb13': 'Please be sure that the patient brings/wears any hearing aids/devices to any appointment(s)',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation14
	'physio14': 'Developmental History',
	'pldam14': 'Please list and describe any medications or supplements the patient currently takes',
	'mn14': 'Medication Name',
	'd14': 'Dosage',
	'freq14': 'Frequency',
	'wtmisf14': 'Reason for the Medication',
	'addMed14': 'Add Another Medications',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation15
	'physio15': 'Developmental History',
	'hpaip15': "Has the patient or anyone in the patient's immediate family experienced any of the following in the last 2 years?",
	'm15': ' Marriage',
	'd15': ' Divorce',
	's15': ' Separation',
	'mo15': ' Moved',
	'accident': ' Accident',
	'death': ' Death',
	'SeriousIllness': ' Serious Illness',
	'OtherEvent': ' Other Significant Event',
	'not15': ' None of these',
	'pleaseFurther15': 'Please explain your selection(s) further',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation16
	'physio16': 'Developmental History',
	'plapf16': 'Please describe any major medical or mental illness in the patient’s family',
	'fn16': 'First Name',
	'ag16': 'Age',
	'rtp16': 'Relationship to Patient',
	'lod16': 'Living or deceased',
	'doc16': 'Diagnosis or Condition',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation17
	'physio17': 'Developmental History',
	'pdam17': "Please list all of the patient's siblings NOT living in the home. Include their first names, their ages and living or deceased.",
	'h0_17': 'Siblings',
	'h1_17': 'Full Siblings',
	'h2_17': 'Half Siblings',
	'h3_17': 'Step Siblings',
	'c1_17': 'First Name',
	'c2_17': 'Age',
	'c3_17': 'Gender',
	'c4_17': 'Living Or Deceased',
	'c5_17': 'Type',
	'addMore17': 'Add Another Sibling',

	//https://seacole.io/patients/appointment/intake/SchoolInformation1
	'schoo1': 'School History',
	'dtpc1': 'Does the patient currently attend school?',
	'ys1': 'Yes',
	'ns1': 'No',
	'tbs1': 'What school does the patient attend?',
	'wgs1': 'What grade are they in?',

	//https://seacole.io/patients/appointment/intake/SchoolInformation2
	'schoo2': 'School History',
	'dtpc2': 'Has the patient experienced any of the following?',
	'ys2': 'Yes',
	'ns2': 'No',
	'freqAbs2': 'Has had frequent absences',
	'sus2': 'Has been suspended',
	'repeatGrade2': 'Has repeated a grade',
	'expelled2': 'Has been expelled',
	'noneOf2': 'None of these',
	'explainFur2': 'Please explain',

	//https://seacole.io/patients/appointment/intake/SchoolInformation3
	'schoo3': 'School History',
	'dtpc3': 'Has or had the patient ever been required to repeat a grade in school?',
	'ys3': 'Yes',
	'ns3': 'No',

	//https://seacole.io/patients/appointment/intake/SchoolInformation4
	'schoo4': 'School History',
	'dtpc4': 'Has or had the patient ever been suspended from school?',
	'ys4': 'Yes',
	'ns4': 'No',
	//https://seacole.io/patients/appointment/intake/SchoolInformation5
	'schoo5': 'School History',
	'dtpc5': 'Has or had the patient ever been expelled from school?',
	'ys5': 'Yes',
	'ns5': 'No',
	//https://seacole.io/patients/appointment/intake/SchoolInformation6
	'schoo6': 'School History',
	'dtpc6': 'Has the patient ever been tested or evaluated at school for special education, an IEP, 504 plan, or gifted programming?',
	'ys6': 'Yes',
	'ns6': 'No',
	'tbs6': 'Please explain',
	'tbsi6': 'Please upload any related documentation below.',

	//https://seacole.io/patients/appointment/intake/SchoolInformation7
	'schoo7': 'School History',
	'dtpc7': 'Has or had the patient ever received special education services at school?',
	'ys7': 'Yes',
	'ns7': 'No',
	'sph7': 'Please explain further',

	//https://seacole.io/patients/appointment/intake/SchoolInformation8
	'schoo8': 'School History',
	'dtpc8': 'What is your highest level of education?',
	'sph8': 'Please explain further',

	//https://seacole.io/patients/appointment/intake/SchoolInformation9
	'schoo9': 'School History',
	'dtpc9': 'What is your highest level of education?',
	'sph9': 'Please explain further',

	//https://seacole.io/patients/appointment/intake/UploadRecords
	'uploadr': 'Upload Records',
	'uploadmers': 'Please upload any additional documentation that may be helpful to the provider completing the patient’s evaluation.',
	'uploadf': 'Upload Documentation',
	'filen': 'File Name: ',
	'file': 'File',
	'type': 'Type',
	'action': 'Action',
	'upload': 'Upload',
	'noFiles': 'No File Chosen',
	'uploadingProgress:': 'Uploading Progress',

	//https://seacole.io/patients/appointment/intake/provider-finder
	'headpf': 'How would you like to choose your assessment specialist?',
	'c1pf': "Patient's Primary Language",
	'c2pf': 'Parent/Guardian Primary Language',
	'c3pf': 'Preferred Location',

	//https://seacole.io/patients/appointment/intake/provider-list-form
	'hplf': 'Select Psychometrist',

	///https://seacole.io/patients/appointment/intake/authority-parent

	'PAH': 'Who has medical decision making authority for this minor?',
	'MDA': 'Minor Medical Authority',
	'TLISH': 'Two legally authorized parents or legal guardians living in the same household',
	'TNLISH': 'Two legally authorized parents or legal guardians not living in the same household, with or without court orders.',
	'OWCO': 'One parent with court order or other legal documentation',
	'OWOCO': 'None of these',
	'legallyAuthorized': 'Are both legally authorized parents or legal guardians listed on the birth certificate or court documentation?',

	//https://seacole.io/patients/appointment/intake/upload-court-order

	'UCO': 'Upload Court Order',

	//https://seacole.io/patients/appointment/intake/partner-consent-info

	'PCI': 'Other Parent or Legal Guardian’s Consent Information',
	'PFN': `Enter the other parent or legal guardian’s first name`,
	'PLN': `Enter the other parent or legal guardian’s last name`,
	'PE': `Enter the other parent or legal guardian’s email address`,
	'PPN': `Enter the other parent or legal guardian’s phone number`,
	'EI': 'An email will be sent to the other parent with a link to take their consent for booking the appointment for the child',
	'unableToProvidePartnerInfo': 'I am unable to provide partner information, or I would like to receive a call from a Patient Care Coordinator before sending secondary consents.',

	//https://seacole.io/patients/appointment/intake/partner-consent-info
	'parentGuardian': 'Parent/Guardian',
	'selectType': 'Select Type',
	'type': 'Type',
	'lastName': 'Last Name',
	'middleInitial': 'Middle Initial',
	'dob': 'Date of Birth',
	'occupation': 'Occupation',
	'sex': 'Sex',
	'homeAddressSameAsClient': 'Home address same as client?',
	'homePhone': 'Home Phone',
	'cellPhone': 'Cell Phone',
	'workPhone': 'Work Phone',
	'highestLevelOfEduCompleted': 'Highest Level of Education Completed',

	//https://seacole.io/patients/appointment/intake/partner-consent-info
	'school': 'School',
	'doesChildAttendSchool': 'Does the child currently attend school?',
	'hasChildBeenAttainedFromSchool': 'Has child ever been retained at school?',
	'hasChildBeenSuspendedFromSchool': 'Has child ever been suspended from a school?',
	'hasChildBeenExpelledFromSchool': 'Has child ever been expelled from a school?',
	'hasChildBeenTestedAtSchool': 'Has child ever been tested at school recently?',
	'hasChildBeenTestedAsGifted': 'Has child ever been tested as gifted?',
	'doesChildReceiveSpecialEdu': 'Does your child receive special education services?',

	//https://seacole.io/patients/appointment/intake/attestation
	'attestationForm': 'Attestation Form',
	'attestConfirm': 'By signing below, I attest that the information provided in this document is true and accurate to the best of my knowledge.',
	'saveAttestation': 'Save',

	//https://seacole.io/patients/appointment/intake/care-cordinator
	'careCoordinatorWillReachOut': 'Our team will contact you in the next few business days to discuss the next steps required to schedule your evaluation. If you have any questions, please contact us at (602) 888-8882.',

	//https://seacole.io/patients/appointment/intake/insurance-gentry-scholarship-stop
	'gentryScholarshipStopUploadedDocuments': 'If you uploaded documents our team will review them in the next few business days and will either contact you to discuss any issues or send you a link via email to continue the intake process.',
	'gentryScholarshipStopNotUploadedDocuments': 'If you did not upload documents our team will reach out to you to discuss what is required to continue.',
	'gentryScholarshipStopContact': 'If you have any questions, please contact us at (602) 888-8882.',

	'nextSteps': 'Next Steps',


	//https://seacole.io/patients/appointment/intake/care-cordinator2
	'emailHasSent': 'An email has been sent to the other parent with the consent link, once approved you will receive a continuation link via email. Thank you for your patience.',
	'okay': 'Okay',

	//https://seacole.io/patients/appointment/intake/care-cordinator3
	'thankYouYouWillReceiveLink': 'Thank you! You will receive a link to continue with scheduling once the court order is reviewed.',

	//https://seacole.io/patients/appointment/intake/adult-patient-notification
	'callUsToRegisterAdultPatient': 'If you are interested in registering an adult patient, please contact us at (602) 888-8882.',

	//https://seacole.io/patients/appointment/intake/care-cordinator-contact
	'careCoordinatorWillReachOutIn1to4': 'A patient care coordinator will reach out within 1 to 4 business days to assist you. Please call 602-888-8882 with any questions.',

	//https://seacole.io/patients/appointment/intake/provider-filter
	'filter': 'Filter',
	'lang': 'Language',
	'age': 'Age',
	'ageRange': 'Age Range',
	'above': 'Above',
	'gender': 'Gender',
	'male': 'Male',
	'female': 'Female',
	'specialities': 'Specialities',
	'autism': 'Autism',
	'psyciatary': 'psiciatario',
	'otherLang': 'Other Language',

	//https://seacole.io/patients/appointment/intake/provider-list
	'noProviderFound': 'No Provider found with your selected language.',

	//https://seacole.io/patients/appointment/intake/provider-specific-time
	'pickADay': 'Pick a day and time for the appointment',
	'eachAppointFor2hrs': 'Each appointment is for 2 hours',

	//https://seacole.io/patients/appointment/intake/growth-progress-1
	'growthProg': 'Growth Progress',
	'childFirstSitAlone': 'At what age did your child first sit alone?',
	'childFirstStandAlone': 'At what age did your child first stand alone?',
	'childFirstWords': 'At what age did your child say their first words?',
	'childFirstCombineWords': 'At what age did your child first combine 2 words?',
	'childFirst34Words': 'At what age did your child first use 3-4 word sentences?',
	'anyDifficultyDuringToileting': 'Please indicate any difficulties your child has had with toileting',
	'explain': 'Explain',
	'difficultyEating': 'Please indicate any difficulties your child has had with eating',
	'difficultySleeping': 'Please indicate any difficulties your child has had with sleeping',

	//https://seacole.io/patients/appointment/intake/growth-progress-2
	'worryAboutChildDevelopment': 'When did you start to worry about the child&apos;s development?',
	'anyPrevDiagnosis': 'Does the child have any previous diagnoses? If yes, please specify',
	'receivingAnySpecialService': 'Is he/she receiving any special services currently? If yes, please indicate and by whom.',
	'seriousIllness': 'Any serious illnesses or hospitalizations?',
	'seriousHeadInjuries': 'Any serious head injuries or accidents?',
	'visionProblems': 'Vision problems or glasses?',
	'hearingProblems': 'Hearing problems or hearing aids?',
	'childHasTraumaHistory': 'Has your child had a history of any trauma, abuse, or neglect?',
	'childDiagnoseWithDisorder': 'Has child been diagnosed with a psychological or behavioral disorder?',
	'upload': 'Upload',

	//https://seacole.io/patients/appointment/intake/insurance-photo
	'addPhoto': 'Let&apos;s add a photo of your card',
	'whenAddPhoto': 'When adding photos, place your card on a flat surface for best results. We&apos;ll ask for photos of the front of the card, followed by the back.',
	'addPhotos': 'Add Photos',
	'addManually': 'Add Manually',
	'skipThis': 'SKIP THIS',

	//https://seacole.io/patients/appointment/intake/insurance-pre-auth
	'insuranceRequiresPreAuth': 'Prior authorization is required',
	'patientCareCoordinatorWillReachOut': 'Unfortunately, your insurance provider requires prior authorization before we can schedule your autism evaluation. A patient care coordinator will be reaching out to you to discuss next steps. If you have any questions, please contact our office at (602) 888-8882.',

	//https://seacole.io/patients/appointment/intake/insurance-private-pay
	'privatePayStartsAt1': 'If you are interested in private pay, the out-of-pocket cost for the evaluation starts at',
	'privatePayStartsAt2': 'for children and starts at',
	'privatePayStartsAt3': 'for adults. A patient care coordinator will reach out within 48 hours to assist you. Please call 602-888-8882 with any questions.',

	//https://seacole.io/patients/appointment/intake/insurance-verify
	'isYourPrimaryInsurance': 'Is this your primary insurance?',

	//https://seacole.io/patients/appointment/intake/secondary-insurance
	'patientRelationToInsured': 'Patient Relationship to insured',
	'zipCodeNotInUS': 'zip-code doesn&apos;t correspond to any US state/city',

	//https://seacole.io/patients/appointment/intake/patient-age
	'patientAge': 'Please tell us your patient&apos;s age',
	'groupNo': 'Group Number',

	//https://seacole.io/patients/appointment/intake/patient-information1
	'tellAboutPatient': 'Please tell us about your patient',
	'childFirstName': 'Child&apos;s First Name',
	'childLastName': 'Child&apos;s Last Name',
	'childMiddleIntials': 'Child&apos;s Middle Initial',
	'placeOfBirth': 'Place of Birth',

	//https://seacole.io/patients/appointment/intake/patient-information2
	'homeAddress': 'Home Address',
	'residenceCountry': 'Residence Country',

	//https://seacole.io/patients/appointment/intake/patient-information3
	'nickName': 'Nickname',
	'socialSecurityNumber': 'Social Security Number',
	'ethnicity': 'Ethnicity',

	//https://seacole.io/patients/appointment/intake/patient-information4
	'primaryLang': 'Primary Language',
	'langAtHome': 'Language Spoken at Home',
	'withWhomeChildLive': 'With whom does the child live?',

	//https://seacole.io/patients/appointment/intake/payments/checkout-form
	'checkoutForm': 'CHECKOUT FORM',
	'cardholderName': 'cardholder name',
	'expiration': 'expiration',
	'cardNumber': 'card number',
	'valid': 'VALID',
	'thru': 'THRU',
	'secCode': 'security code',

	//https://seacole.io/patients/appointment/intake/payments/payment-form
	'amountToBePaid': 'Amount to be paid is',
	'payFull': 'PAY FULL',
	'payHalf': 'PAY HALF',

	//https://seacole.io/patients/appointment/intake/payments/payment-option-covered
	'paymentOptionCovered': 'Payment Options Covered',

	//https://seacole.io/patients/appointment/intake/payments/payment-option-not-covered
	'paymentOptionNotCovered': 'Payment Options Not Covered',

	//https://seacole.io/patients/appointment/intake/all-set
	'allSet': 'You&apos;re all set!',
	'keepALookOut': 'Please keep a look out for more forms and information required for the appointment.',
	'goToHome': 'Go to home page',

	//https://seacole.io/patients/appointment/intake/birth-information
	'birthMotherUse': 'Did birth mother use any of the following during pregnancy?',
	'lengthOfPreg': 'Length of Pregnancy',
	'birthWeight': 'Birth Weight',
	'birthHeight': 'Birth Height',
	'typeOfBirth': 'Type of Birth',

	//https://seacole.io/patients/appointment/intake/diet
	'diet': 'Diet',
	'listMedications': 'List any medications or supplements child receiving (medication, dosage, time(s) given)',
	'eatingHabits': 'Describe eating habits',
	'anySpecialDiet': 'Any special diet or food allergies (Yes or No). If so, please describe',
	'prevPsycEval': 'Has child had any previous Psychological - Psychiatric Evaluations?',
	'whatAreChildStrengths': 'What are the child&apos;s strengths?',
	'whatAreFamilyStrengths': 'What are the family&apos;s strengths?',

	//https://seacole.io/patients/appointment/intake/family-information
	'parentsAreSeperated': 'If parents are separated/divorced, who has custody?',
	'noOfSiblings': 'Number of Siblings',
	'otherMembersLiving': 'Other members living in the household?',
	'pleaseListRelationsToClient': 'If yes, please list names, sex, age & relationship to client',
	'familyExperience': 'Family experienced any of the following in the last two (2) years?',
	'medicatlIllnessInFamily': 'Describe any major medical or mental illness(es) in family',
	'useDuringPreg': 'Did birth mother use any of the following during pregnancy?',

	//https://seacole.io/patients/appointment/intake/modality
	'likeToBeSeen': 'Would you like to be seen at the office or remotely at home?',
	'atHome': 'At Home',
	'remote': 'Remote',

	//https://seacole.io/patients/appointment/intake/physician
	'tellAboutPhys': 'Please tell us about your child&apos;s physician',
	'address': 'Address',

	//https://seacole.io/patients/appointment/intake/reason
	'reasonForEval': 'Reason for Evaluation',
	'patientToBeSeen': 'Please describe why you want your patient to be seen at this time.',
	'yourGoals': 'What are you goals for the evaluation?',

	//https://seacole.io/patients/appointment/intake/school
	'childAttendSchool': 'Does the child currently attend school?',
	'childRetainedAtSchool': 'Has child ever been retained at school?',
	'childEverSuspended': 'Has child ever been suspended from a school?',
	'childEverExpelled': 'Has child ever been expelled from a school?',
	'childEverTested': 'Has child ever been tested at school recently?',
	'childEverTestedGifted': 'Has child ever been tested as gifted?',
	'childReceiveSpecialEdu': 'Does your child receive special education services?',

	//https://seacole.io/patients/appointment/intake/someone-will-reach-out
	'someoneWillReach': 'Someone will reach out.',

	//https://seacole.io/patients/appointment/intake/tell-us
	'tellUs': 'Tell Us',
	'tellUsAnything': 'Please tell us anything you think would be helpful in understanding you, or this child. Include questions you may have.',

	//https://seacole.io/patients/appointment/intake/upload-records
	'download': 'Download',

	//https://seacole.io/patients/appointment/intake/invoice
	'patientDetials': 'Patient Details',
	'shortPhone': 'Phone',
	'shortEmail': 'Email',
	'appointDetails': 'Appointment Details',
	'invoiceName': 'Invoice Name',
	'invoiceId': 'Invoice Id',
	'details': 'Details',
	'paymentType': 'Payment Type',
	'fees': 'Fees',
	'paymentOn': 'Payment On',
	'contactDetails': 'Contact Details',
	'note': 'Note',
	'subtotalAmt': 'Sub - Total Amount',
	'discount': 'Discount',
	'grandTotal': 'Grand Total',
	'makeAPayment': 'MAKE A PAYMENT',
	'incorrectToken': 'You provide an incorrect token for invoice Or Invoice not created with this token.',
	'contactAdmin': 'Please contact admin.',

	//https://seacole.io/patients/appointment/intake/profile/account
	'myAccount': 'My Account',

	//https://seacole.io/patients/appointment/intake/profile
	'myProfile': 'My Profile',
	'logout': 'Logout',

	//https://seacole.io/patients/appointment/intake/provider-finder-reschedule
	'chooseYourAssess': 'How would you like to choose your assessment specialist?',
	'patPrimaryLang': 'Patients Primary Language',
	'shortSelect': 'Select',

	//https://seacole.io/patients/appointment/intake/provider-list-reschedule
	'selectPsyc': 'Select Psychiatric/Therapist?',

	//https://seacole.io/patients/appointment/intake/provider-specific-time-reschedule
	'pickADay': 'Pick a day and time to reshedule the appointment',
	'appointFor2hrs': 'each appointment is for 2 hours.',

	//https://seacole.io/patients/appointment/checkout
	'checkoutOnlyForm': 'CHECKOUT ONLY FORM',
	'pay': 'PAY',

	//https://seacole.io/patients/appointment/messages
	'messages': 'Messages',
	'noMessages': 'No Messages',

	//https://seacole.io/patients/appointment/library
	'library': 'Library',
	'libraryComingSoon': 'Library is coming soon!',

	//https://seacole.io/patients/appointment/my-membership
	'myMembership': 'My Membership',
	'membershipComingSoon': 'Membership is coming soon!',

	//https://seacole.io/patients/appointment/my-records
	'myRecords': 'My Records',
	'recordsComingSoon': 'Records are coming soon!',

	//https://seacole.io/patients/appointment/my-tasks
	'myTasks': 'My Tasks',
	'noTasks': 'No Tasks',

	//https://seacole.io/patients/appointment/release-of-information-form
	'save': 'Save',

	// /patients/pages/intake/consent/ConsentClinicalServicesForm.vue
	'consentForClinical': 'Consent for Clinical Services',
	'profRecords': 'Professional Records',
	'lawStandards': `The law and standards of our profession require that your 
	clinician keep treatment records. You are entitled to receive a copy of your 
	records, or your clinician can prepare a summary for you. Note: copyrighted 
	materials used in diagnostics and/or privileged information would not be included 
	in in the record set.  Because these are professional records, they can be 
	misinterpreted by and/or upsetting to untrained readers. If you wish to see your 
	records, we recommend that you review them in your clinician’s presence so that 
	the contents can be discussed. Professional fees will be charged for any time spent 
	in responding to information requests.  Fees can be requested by contacting our 
	office at 602-888-8882.`,
	'patientConf': 'Patient/Clinician Confidentiality',
	'inGeneralLaw': `In general, the law protects the privacy of all communications between a legal guardian or adult patient 
	and a psychologist, and your clinician can release information to others only with your written permission.<b> But there are a few exceptions.</b> In most 
	legal proceedings, you have the right to prevent your clinician from providing any information about the patient's evaluation and treatment. In some 
	proceedings involving child custody and those in which your emotional condition is a critical issue, a judge may order your clinician’s 
	testimony if they determine the issue demands it. There are some situations in which your clinician is legally obligated to take action to protect the 
	patient and others from harm, even if they must reveal some information about 
	a patient’s evaluation/treatment. For example:`,
	'ifYourClinicianBelievesAChild': 'If your clinician believes that a child, elderly person, disabled person, etc., is being abused or neglected, they must file a report with the appropriate state agency.',
	'ifYourClinicianBelievesARisk': `If your clinician believes that you are at imminent risk of harming yourself, they may contact law enforcement or other crisis services. However, before contacting emergency 
	or crisis services, your clinician will work with you to discuss other options to keep you safe.`,
	'ifYourClinicianBelievesAThreat': `If your clinician believes there is a specific, credible threat (defined by state law) of harm to someone else, they are required by law to take action. This means that 
	if a patient, legal guardian or any other related/involved, is threatening serious bodily harm to another, your clinician is required to take actions which may include 
	notifying the potential victim, contacting the police, and/or seeking hospitalization for the individual, and/or contacting family members or others who can help 
	provide protection.`,
	'ifAnotherHealthProvEngaging': `If you report that another healthcare provider is engaging in inappropriate behavior (including behavior that does not pose a safety threat), your clinician 
	may be required to report this information to the appropriate licensing board. Your clinician will discuss making this report with you first and will only 
	share the minimum information needed while making a report. If your clinician must share your personal information without obtaining your permission first,
	they will only share the minimum information needed. There are a few times that your clinician may not be able to legally keep your personal information confidential.`,
	'yourClinicianNeedToConsult': `Your clinician may occasionally need to consult other professionals about a case or receive required supervision for licensure. During a consultation/supervision session, 
	they make every effort to avoid revealing the identity of the individual. The consultant/supervisor is also legally bound to keep the information confidential. 
	If you don’t object, your clinician will not tell you about these consultations unless they feel it is important to your work together.`,
	'writtenNoticeToConfidentiality': `While this written notice of exceptions to confidentiality is helpful in informing you about potential problems, it is important that you and your clinician discuss any 
	questions or concerns that you may have. They will be happy to discuss these issues with you if you need specific advice, but formal legal advice may be needed because 
	the laws governing confidentiality are quite complex, and your clinician is not an attorney.`,
	'dndEvals': 'Diagnostic & Developmental Evaluations',
	'privatePayRateServices': `Private pay rates for services can be obtained by calling our office at 602-888-8882, (if not utilizing insurance). 
	Rates include observations, assessment and assessment scoring, teacher/guardian interviews (if applicable), report writing and meeting attendance. `,
	'bnrEvals': 'Benefits and Risks of Evaluations',
	'riskEnvolvedInAssess': `Although the risks involved in assessment are much less than during traditional psychotherapy, the limits of confidentiality are the same and can be read in the 
	confidentiality section of this agreement.`,
	'diagnosticConclusionAreNotExpected': `It is possible that the diagnostic conclusions we make are not what you expected. Thus, while evaluation and diagnosis often lead to appropriate services, 
	it may also make it more difficult to obtain services (as might be the case for a patient who does not receive a diagnosis). When completing an evaluation with a minor, 
	their guardian has the right to view the final report. A minor’s guardian(s)/patient may request and complete a Release of Information (ROI) Form if they would like 
	us to share the report with other entities.`,
	'axisForAutismSpecialty': `Axis for Autism is a specialty provider and may not be the most appropriate provider for you or your family member. If we become aware of this, we will give you 
	referrals to other practitioners whom we believe are better suited to help you.   `,
	'diagnosticeEvalInvolveCommitment': `Diagnostic evaluations involve a large commitment of time and energy from patients and family members. It is not a quick process. Please inform your care team as 
	soon as possible if you have concerns about time commitments.`,
	'inLegalProceedings': `In some legal proceedings, a judge may order your provider's testimony if he/she determines that the issues demand it, and we must comply with that court order.`,
	'riskOfTreatmentInvolveDisagreement': `One risk of child treatment involves disagreement among parents and/or disagreement between parents and the clinician regarding the child’s treatment.  If such 
	disagreements occur, the clinician will strive to understand your perspectives and fully explain their perspective.  We can resolve such disagreements, or we can 
	agree to disagree, so long as this enables the medically necessary standard of care.  Due to medical documentation requirements, all information is documented, 
	and any information shared by any party would be visible to all parties who have legal rights to review the record.  This means information shared by one parent 
	will be available to the other parent for review. `,
	'courtOrderToTestifyInCustodyLitigation': `If court ordered to testify in custody litigation, our psychologists are ethically bound not to give an opinion about either parent’s custody, 
	visitation suitability, or fitness.  If the court appoints a custody evaluator, guardian ad litem, or parenting coordinator, we will provide 
	information as needed, if appropriate releases are signed or a court order is provided.  Furthermore, if a psychologist is required to appear 
	as a witness or to otherwise perform work related to any legal matter, the party responsible for the participation/requirement agrees to pay 
	Axis for Autism an hourly rate per hour for time spent traveling, speaking with attorneys, reviewing and preparing documents, testifying, being 
	in attendance, and any other case-related costs.`,
	'signBelowAcknowlegde': 'With my signature below, I acknowledge',
	'readAllInfo': 'I have read all of the information contained in this Informed Consent for Clinical Services Document and that I have been fully informed about the nature of the evaluation, risks involved and desired benefits.',
	'legalyCompetent': 'I am legally competent and have the authority to provide consent for evaluation.',
	'volutarilyAgree': 'I voluntarily agree to receive a diagnostic evaluation and understand that I may discontinue services in writing at any time.',
	'agreeToTerms': 'I agree to terms and conditions above',
	'signBelow': 'Sign Below',

	// /patients/pages/intake/consent/NoticeHealthInformationPractices.vue
	'nhip_noticeOfHIP': 'Notice of Health Information Practices',
	'nhip_recievingNoticeBcz': `You are receiving this notice because your healthcare provider participates in a non-profit,
	non-governmental health information exchange (HIE) called Health Current. It will not cost you
	anything and can help your doctor, healthcare providers, and health plans better coordinate your care by securely sharing your health information. This Notice explains
	how the HIE works and will help you understand your rights regarding the HIE under state and federal law.`,
	'nhip_howHealthCurrHelp': 'How does Health Current help you to get better care?',
	'nhip_inPaperBasedRecord': `In a paper-based record system, your health information is mailed or faxed to your doctor, but
	sometimes these records are lost or don’t arrive in time for your appointment. If you allow your health information to be shared through the HIE, your doctors are able to
	access it electronically in a secure and timely manner.`,
	'nhip_healthInfoAvailable': 'What health information is available through Health Current?',
	'nhip_typesOfHealthInfo': 'The following types of health information may be available:',
	'nhip_hospitalRecords': 'Hospital Records',
	'nhip_medicalHistory': 'Medical History',
	'nhip_medications': 'Medications',
	'nhip_allergies': 'Allergies',
	'nhip_testResults': 'Lab test results',
	'nhip_radiologyReport': 'Radioligy Reports',
	'nhip_clinicVisitInfo': 'Clinic and doctor visit information',
	'nhip_healthPlanEnrol': 'Health plan enrollment and eligibility',
	'nhip_otherInfoNedded': 'Other information needed for treatment',
	'nhip_whoViewYourHelathInfo': 'Who can view your health information through Health Current and when can it be shared?',
	'nhip_peopleInvolved': `People involved in your care will have access to your health information. This may include your
	doctors, nurses, other healthcare providers, health plan and any organization or person who is
	working on behalf of your healthcare providers and health plan. They may access your information
	for treatment, care coordination, care or case management, transition of care planning, payment for
	your treatment, conducting quality assessment and improvement activities, developing clinical
	guidelines and protocols, conducting patient safety activities, and population health services. Medical
	examiners, public health authorities, organ procurement organizations, and others may also access
	health information for certain approved purposes, such as conducting death investigations, public
	health investigations and organ, eye or tissue donation and transplantation, as permitted by applicable law.`,
	'nhip_healthCurrentMayUse': `Health Current may also use your health information as required by law and as necessary to perform
	services for healthcare providers, health plans and others participating with Health Current.
	The Health Current Board of Directors can expand the reasons why healthcare providers and others
	may access your health information in the future as long as the access is permitted by law. That
	information is on the Health Current website at healthcurrent.org/permitted-use.`,
	'nhip_permitOtherToAccess': `You also may permit others to access your health information by signing an authorization form. They may only access the health information described in the authorization
	form for the purposes stated on that form.`,
	'nhip_doesHealthCurrRecievesBehaviorInfo': 'Does Health Current receive behavioral health information and if so, who can access it?',
	'nhip_healthCurrRecievesBehavInfo': `Health Current does receive behavioral health information, including substance abuse treatment
	records. Federal law gives special confidentiality protection to substance abuse treatment records
	from some substance abuse treatment programs. Health Current keeps these protected substance
	abuse treatment records separate from the rest of your health information. Health Current will only
	share these protected substance abuse treatment records it receives from these programs in two cases.  One, medical personnel may access this information in a medical
	emergency. Two, you may sign a consent form giving your healthcare provider or others access to this information.`,
	'nhip_howHealthInfoProtected': 'How is your health information protected?',
	'nhip_federalAndStateLaws': `Federal and state laws, such as HIPAA, protect the confidentiality of your health information. Your
	information is shared using secure transmission. Health Current has security measures in place to prevent someone who is not authorized from having access.
	Each person has a username and password, and the system records all access to your information.`,
	'nhip_rightsReagrdingSecureElecInfoSharing': 'Your Rights Regarding Secure Electronic Information Sharing',
	'nhip_youHaveRightTo': 'You have the right to',
	'nhip_askCopyOfHealthInfo': `Ask for a copy of your health information that is available through Health Current. To make this request, complete the Health Information Request Form and return it 
	to your healthcare provider.`,
	'nhip_reqToHaveInfoCorrected': `Request to have any information in the HIE corrected. If any information in the HIE is incorrect, you can ask your healthcare provider to correct the information.`,
	'nhip_askForPeopleWhoViewed': `Ask for a list of people who have viewed your information through Health Current. To make this request, complete the Health Information Request Form and return it to 
	your healthcare provider. Please let your healthcare provider know if you think someone has viewed your information who should not have.`,
	'nhip_youHaveRightUnderArticle27': `You have the right under article 27, section 2 of the Arizona Constitution and Arizona Revised Statutes title 36, section 3802 to keep your health information from being
	shared electronically through Health Current:`,
	'nhip_optOutOfSharingYourInfo': `Except as otherwise provided by state or federal law, you may “opt out” of having your information shared through Health Current. To opt out, ask your
	healthcare provider for the Opt Out Form. Your information will not be available for sharing through Health Current within 30 days of Health Current
	receiving your Opt Out Form from your healthcare provider.  Caution: If you opt out, your health information will NOT be available to your healthcare
	providers—even in an emergency.`,
	'nhip_optOutAndChangeMindLater': `If you opt out today, you can change your mind at any time by completing an Opt Back In Form and returning it to your healthcare provider.`,
	'nhip_optOutInFuture': 'If you do nothing today and allow your health information to be shared through Health Current, you may opt out in the future.',
	'nhip_infoWillBeSecurelyShared': 'IF YOU DO NOTHING, YOUR INFORMATION MAY BE SECURELY SHARED THROUGH HEALTH CURRENT.',
	'nhip_noticeOfHIPInitials': 'Notice of Health Information Practices (all initials)',
	'nhip_ackThatIReadNHIP': 'I acknowledge that I received and read the Notice of Health Information Practices.',
	'nhip_myHealthProvParticipatesInHealthCurr': 'I understand that my healthcare provider participates in Health Current, Arizona’s health information exchange (HIE).',
	'nhip_myInfoBeSecurelyShared': 'I understand that my health information may be securely shared through the HIE, unless I complete and return an Opt Out Form to my healthcare provider.',
	'nhip_agreeToTerms': 'I agree to terms and conditions above',
	'nhip_signBelow': 'Sign Below',

	// /patients/pages/intake/consent/NoticePrivacyPractices.vue
	'npp_noticeOfPrivacyPractices': 'Notice of Privacy Practices',
	'npp_axisAutismCommittedToProtect': `Axis for Autism, LLC is committed to protecting the privacy or our patient’s
	health information. In
	accordance with the Health Insurance Portability and Accountability Act
	(HIPAA), this notice
	describes our privacy practices and explains how we may use and disclose
	your protected health
	information (PHI) to carry out treatment, payment or health care operations
	and for other purposes
	that re permitted or required by law. Axis for Autism is required, by law,
	to maintain the privacy
	and confidentiality of your protected health information and to provide our
	patients with notice of
	our legal duties and privacy practices with respect to your protected health
	information.`,
	'npp_termsOfNoticeApplytoRecords': `The terms of this notice apply to all records containing your PHI that are
	created or retained by
	Axis for Autism. We reserve the right to revise or amend this Notice of
	Privacy Practices. Any
	revision or amendment to this notice will be effective for all of your
	records that our practice has
	created or maintained in the past, and for any of your records that we may
	create or maintain in the
	future. Axis for Autism will post a copy of our current Notice in our
	offices in a visible location at all
	times and you may request a copy of our most current notice at any time.`,
	'npp_whenBeginWorkingWithAxis': `When you begin working with Axis for Autism, a record of treatment is made.
	This record contains
	your history, assessment, medical information, diagnosis, treatment, a plan
	for future treatment,
	etc. This information is often referred to as a medical record, and serves
	as:`,
	'npp_basisForPlanning': 'The basis for planning your care and treatment.',
	'npp_docDescribingCare': 'A legal document describing the care you received.',
	'npp_meanByWhichVerify': `A means by which you or a third-party payer can verify that services billed were provided.`,
	'npp_sourceOfData': `A source of data for officials charged with improving care or needed services.`,
	'npp_toolByWhichServicesApproved': 'A tool by which future or continuing services can be approved.',
	'npp_understandingWhatIsInRecords': `Understanding what is in this record will help you ensure its accuracy,
	better understand who,
	what, when and why others may access your information and to help you make
	informed decisions
	when authorizing disclosure to others. Although your health record is the
	physical property of Axis
	for Autism, the information belongs to you. You have the following rights:`,
	'npp_toInspectYourPHI': `To inspect and copy your PHI that the company uses for making decisions
	about you, with certain exceptions. The company will respond to you within thirty (30) days.`,
	'npp_reqARestrictionOnUse': `Request a restriction on our use and sharing of your PHI. The company may
	deny the request if it is unreasonable or would be detrimental to treatment.`,
	'npp_paperCopyOfNotice': 'A paper copy of this Notice ',
	'npp_reqCompanyToAmend': `Request the company to amend the PHI maintained about you if you feel it is
	incorrect or incomplete for as long as the information is kept by the company. To request
	an amendment, you must submit a request in writing to your assigned supervisor
	and state the reason that supports your request. The disputed information will remain in
	the record along with the amended information. The company may deny your request if the
	request is not submitted in writing, does not contain a reason to support the request,
	the information	that is being questioned did not originate from the company, it is not part
	of the information which you are permitted to inspect or copy, or it is currently accurate and
	complete.`,
	'npp_obtainAccountingOfDisclosures': `Obtain an accounting of the disclosures the company made regarding your PHI.
	It excludes	disclosures the company may have made to you, if you authorized us to make
	the disclosure for a company directory, to family members or friends involved in your care,
	for notification purposes, for national security or intelligence, to law enforcement (as
	provided in the	privacy rule) or correctional facilities, as part of a limited data set
	disclosure. The right to receive this information is subject to certain exceptions, restrictions, and
	limitations.`,
	'npp_reqCommunicationOfHealthInfo': `Request communication of your health information by alternative means or
	alternative	location. For example, you could request the clinician only contact you at
	work or by mail. To request communications by alternative means, you must submit your request
	in writing to your assigned supervisor. You will not be asked the reason for your
	request and your request will be accommodated. Your request must indicate how or where you
	want to be contacted.`,
	'npp_exmpOfDisclosureForTreatment': `Example of disclosure for treatment, payment and health care operations: The
	company will use your health information for diagnostic assessment, treatment recommendations
	and on-going treatment and services. The company may disclose your protected health
	information to agency and non-agency personnel who may be involved in your treatment. The company
	will use and disclose your protected health information so that billing and payment for
	services for the treatment can occur. For billing and payment purposes we may disclose
	information to insurance or managed care company, Medicaid, or third-party payor. The company will
	use your health	information for regular health care operations. These uses and disclosures
	are necessary to manage	the agency and our quality of care.`,
	'npp_exmpOfDisclosuresForOtherPurposes': `Examples of uses and disclosures for other specific purposes: As required by
	law the company	may disclose you/your dependent’s protected health information. We may use
	or disclose your protected health information in the following situations without your
	authorization or providing you the opportunity to agree or object. These situations include but are not
	limited to:`,
	'npp_reqByLaw': 'Required by law:',
	'npp_weMayDiscloseYourProtectedHealthInfo': `We may use or disclose your protected health information to the extent that the use or disclosure is 
	required by law. The use or disclosure will be made in compliance with the law and will be limited to the relevant 
	requirements of the law. You will be notified, if required by law, of any such uses or disclosures.`,
	'npp_pbHealth': 'Public Health:',
	'npp_mayDiscloseHealthInfoForHealthActivities': `We may disclose your protected health information for
	public health activities and purposes to a public health authority that is permitted by
	law to collect or receive the information. For example, a disclosure may be made for the
	purpose of preventing or controlling disease, injury or disability.`,
	'npp_communicateDiseases': 'Communicable Diseases:',
	'npp_mayDiscloseHealthInfoToAPersonWithExposedDisease': `We may disclose your protected health information, if authorized by law, to a person who may have been 
	exposed to a communicable disease or may otherwise be at risk of contracting or spreading the disease or condition.`,
	'npp_healthOversight': 'Health Oversight:',
	'npp_mayDiscloseHealthInfoToHealthAgency': `We may disclose protected health information to a health oversight agency for activities authorized by law, 
	such as audits, investigations, and inspections. Oversight agencies seeking this information include government agencies 
	thatoversee the	health care system, government benefit programs, other government regulatory programs and civil rights laws.`,
	'npp_abuseOrNeglect': 'Abuse or Neglect:',
	'npp_mayDiscloseHealthInfoToHealthAuthority': `We may disclose your protected health information
	to a public health authority that is authorized by law to receive reports of child abuse or
	neglect. In addition, we may disclose your protected health information if we believe that you
	have been a	victim of abuse, neglect or domestic violence to the governmental entity or
	agency authorized to receive such information. In this case, the disclosure will be
	made consistent	with the requirements of applicable federal and state laws.`,
	'npp_legalProceedings': 'Legal Proceedings:',
	'npp_mayDiscloseHealthInfoForJudicial': `We may disclose protected health information in the course of any judicial or administrative proceeding, 
	in response to an order of a court or administrative tribunal (to the extent such disclosure is expressly authorized), 
	or in certain conditions in response to a subpoena, discovery request or other lawful process.`,
	'npp_lawEnforcement': 'Law Enforcement:',
	'npp_mayDiscloseHealthInfoForLawEnforcement': `We may also disclose protected health information,
	so long as applicable legal requirements are met, for law enforcement purposes. These
	law	enforcement purposes include (1) legal processes and otherwise required by
	law, (2) limited information requests for identification and location purposes, (3)
	pertaining to victims of a crime, (4) suspicion that death has occurred as a result of
	criminal conduct, (5) in the event that a crime occurs on the premises of our practice, and (6)
	medical	emergency (not on our practice’s premises) and it is likely that a crime has
	occurred.`,
	'npp_ciminalActivity': 'Criminal Activity:',
	'npp_consistentWithApplicableLaw': `Consistent with applicable federal and state
	laws, we may disclose your protected health information, if we believe that the use or disclosure is
	necessary to prevent or lessen a serious and imminent threat to the health or safety of a
	person or the public. We may also disclose protected health information if it is necessary
	for law	enforcement authorities to identify or apprehend an individual.`,
	'npp_militaryActivity': 'Military Activity and National Security:',
	'npp_whenAppropriateConditionsApply': `When the appropriate conditions apply, we may
	use or disclose protected health information of individuals who are Armed
	Forces personnel (1) for the activities deemed necessary by appropriate military
	command	authorities; (2) for the purpose of a determination by the Department of
	Veterans Affairs of your eligibility for benefits, or (3) to foreign military authority if
	you are a member of	that foreign military services. We may also disclose your protected health
	information to authorized federal officials for conducting national security and intelligence activities, 
	including for the provision of protective services to the President or others legally authorized.`,
	'npp_authorizationApply': `Your authorization is required for other uses of protected health information:`,
	'npp_willDiscloseHealthInfoWithWrittenAuthroization': `The clinician will use and disclose protected health information (other than
	described in this Notice or required by law) only with your written authorization. You may
	revoke your	authorization to use or disclose protected health information in writing, at
	any time. If you revoke your authorization, we will no longer use or disclose your protected
	health information for the purposes covered by the authorization except where we
	have already relied on the authorization.`,
	'npp_otherInvolvedInHealthCare': 'Others involved in your health care or payment for your care:',
	'npp_mayDiscloseToFamilyMember': `Unless you object, we may disclose to a member of your family, a relative, a close friend or any
	other person you identify, your protected health information that relates to that person’s
	involvement in your health care. If you are unable to agree or object to such a disclosure,
	we may disclose	such information as necessary if we determine that it is in your best
	interest based on our professional judgment. We may use or disclose protected health information
	to notify or assist in notifying a family member, personal representative or any other
	person that is responsible for your care of your location, general condition, or death.`,
	'npp_mayDiscloseToPublicOrPrivateEntity': `Finally, we may use or disclose your protected health information to an
	authorized public or private entity to assist in disaster relief efforts and to coordinate
	uses and disclosures to	family or other individuals involved in your health care.`,
	'npp_responsibilityRegardingDependents': 'Our responsibility regarding you/your dependent’s protected health information',
	'npp_clinicianRequiredByLaw': 'The clinician is required by law to:',
	'npp_maintainThePrivacy': 'Maintain the privacy of your PHI.',
	'npp_provideWithNoticeOurLegalDuties': `Provide you with notice as to our legal duties and privacy practices with respect to information we collect and maintain about you.`,
	'npp_abideByTerms': 'Abide by the terms of this notice.',
	'npp_notifyIfUnableToAgree': 'Notify you if we are unable to agree to a requested restriction.',
	'npp_accomodateRequests': `Accommodate reasonable requests you may have to communicate health information by alternative means or at alternative locations.`,
	'npp_socialWebsites': 'Social Media/Review Websites',
	'npp_tryToCommunicateWithClinicianViaMedia': `If you try to communicate with your clinician via social media platforms,
	they will not respond. This includes any form of friend or contact request,
	(at)mention, direct message, wall post, and so on. This is to protect your
	confidentiality and ensure appropriate boundaries.`,
	'npp_clinicianMayPublishOnMedia': `Your clinician may publish content on various social media websites or
	blogs. There is no expectation that you will follow, comment on, or
	otherwise engage with any content. If you do choose to follow your clinician
	on any platform, they will not follow you back.`,
	'npp_seeClinicianOnAnyReviewWebsite': `If you see your clinician on any form of review website, it is not a
	solicitation for a review. Many such sites scrape business listings and may
	automatically include your clinician. If you choose to leave a review of
	your clinician on any website, they will not respond. While you are always
	free to express yourself in the manner you choose, please be aware of the
	potential impact on your confidentiality prior to leaving a review. It is
	often impossible to remove reviews later, and some sites aggregate reviews
	from several platforms leading to your review appearing in other places
	without your knowledge.`,
	'npp_signatureBelowIndicatesYouReadInfo': `Your signature below indicates that you have read the information in this
	document and agree to abide by its terms during your patient-clinician
	professional relationship.`,
	'npp_changesToNoticeOfPrivacyPractices': 'Changes to this Notice of Privacy Practices',
	'npp_companyReservesTheRightToMakeChanges': `The company reserves the right to make changes to this notice whenever there is a material change 
	to the uses or disclosures, your individual rights, our legal duties, or other privacy practices stated in this 
	Notice. Any changes made will affect the protected health information we maintain at that time. We maintain a copy 
	of the current notice at our office site. We will provide a revised copy of the notice to legal guardians upon request 
	on or after the effective date of revision.`,
	'npp_concerns': 'Concerns',
	'npp_concernsAboutPrivacyRights': `Concerns about your Privacy Rights or how the company has handled your PHI
	should be directed to the compliance department by calling 602-888-8882 or emailing
	security(at)axisforautism.com You will not be penalized for filing a complaint.
	If you are not satisfied with the way this office handles your complaint,
	you may submit a formal complaint to:`,
	'npp_dhhsOffice': 'DHHS, Office of Civil Rights',
	'npp_200IndpAvn': '200 Independence Avenue,',
	'npp_swRoom': 'S.W. Room 509HHH Building',
	'npp_washingtonDC': 'Washington, DC 20201',
	'npp_haveReadAxisAutismNoticeOfPrivacyPractices': `I have read Axis for Autism - Notice of Privacy Practices and understand my
	rights contained in the	notice. By way of my signature, I provide the company with my authorization
	and consent to use and disclose my PHI for the purposes of treatment, payment, and health care
	operations as described in the Privacy Notice.`,
	'npp_date': 'Date:',
	'npp_agreeToTerms': 'I agree to terms and conditions above',
	'npp_signBelow': 'Sign Below',

	// /patients/pages/intake/consent/ConsentGeneralForm.vue
	'gcf': 'General Consent Form',
	'gcf_professionalServicesInformation': `The following contains important information about the company’s
	professional services and policies as well as guardians’ rights and
	expectations during assessment and/or
	treatment. Please read each section carefully. If you have any questions,
	discuss with a Coordinator before signing/initialing.
	If you are not satisfied with the responses you receive, please call your
	local office and ask for the Supervisor.`,
	'gcf_mustInitialEachTime': `Guardian/patient must initial each item to indicate understanding and agreement.`,
	'gcf_electronicCommuication': 'Electronic Communications',
	'gcf_useOfElectronicMail': `I authorize the use of electronic mail (e-mail) and messaging (texting)for
	any and all correspondence with myself, including scheduling, information
	gathering,
	and for the delivery of reports and other official documents. I understand
	that this means that documents with protected health information may be sent
	over the Internet
	and that the Internet is not always completely safeguarded from unauthorized
	persons. I understand consent may be withdrawn at any time by notifying Axis
	for Autism, in writing,
	at the following address: Axis for Autism, 1645 E. Missouri Ave, Suite 320,
	Phoenix, AZ 85016`,
	'gcf_attendance': 'Attendance and Scheduling',
	'gcf_accompanyingThePatient': `If the patient or anyone accompanying the patient to their appointment is
	sick (i.e., temperature above 99.5°F, green nasal discharge, diarrhea,
	vomiting,
	anything infectious, contagious condition or infestation of any kind, etc.),
	please reschedule your appointment. It is important that our clinicians stay
	healthy so they don’t need to cancel future sessions with you or other
	patients. If the patient or anyone accompanying the patient to their
	appointment
	is sick or not feeling well, please contact your clinician as soon as
	possible, so that another patient may be given that time slot.`,
	'gcf_lateCancellation': 'Late Cancellation Fee',
	'gcf_lateCancellationFee': `A $50 late cancellation fee may be charged for any appointments cancelled
	with less than 24 hours’ notice. This does not apply to rescheduled
	appointments.
	If rescheduling with less than 24 hours happens twice, the clinician may
	contact the parent/legal guardian or adult patient to secure a final
	rescheduled appointment.
	Termination of services may be necessary if excessive cancellations
	continue.`,
	'gcf_contactingUs': 'Contacting Us',
	'gcf_notImmediatelyAvailable': `Axis for Autism clinicians are often not immediately available by telephone.
	While we are usually working between the hours of 8 AM and 5 PM, we will not
	answer the phone if we are with another patient.
	If you are unable to reach your clinician and feel you can’t wait for them
	to return your call, please call or text us at 602-888-8882, or email
	scheduling(at)axisforautism.com.
	Your clinician will make every effort to return your phone call, text, or
	e-mail within 48 hours, except for weekends and holidays,
	or in the event they are out of the office for some reason. If you are
	difficult to reach, please inform your clinician of times you will be
	available.`,
	'gcf_notImmediatelyAvailableHighlight': `If you or your family member have a mental/behavioral health related, or
	other, emergency that may require immediate medical attention, please
	call 911,
	call or text the National Suicide & Crisis Lifeline at 988, contact your
	family physician, or call/go to the nearest emergency room, as we do not
	provide emergency services in our office.`,
	'gcf_extendedHoliday': `If your clinician will be unavailable for an extended holiday, they will provide you with the name of a colleague to contact, if necessary.`,
	'gcf_telehealth': 'Telehealth Consent',
	'gcf_telehealthInformation': `This Informed Consent for Telehealth Services contains important information
	focusing on service provision using the phone or the internet. Please read
	this carefully and let us know if you have any questions. When you sign this
	document, it will represent an agreement between you and Axis for Autism, LLC.`,
	'gcf_telehealthRisk': `Benefits and Risks of Telehealth`,
	'gcf_telehealthRishExplaination': `One of the benefits of telehealth is that the client and clinician can
	engage in services without being in the same physical location. This can be
	helpful in ensuring service provision and continuity of care if the client
	or clinician moves to a different location, takes an extended vacation, or
	is otherwise unable to continue to meet in person. It is also more
	convenient and takes less time. Telehealth, however, requires technical
	competence on both our parts to be helpful. Although there are benefits of
	telehealth, there are some differences between in-person and telehealth, as
	well as some risks. For example:`,
	'gcf_telehealthRiskConfidentiality': `Risks to confidentiality. The laws that protect confidentiality also
	apply to telehealth, though there may be additional limitations or
	risks. Because telehealth takes place outside of a clinician’s private
	office where privacy and confidentiality can be ensured, there is
	potential for other people to overhear sessions if you are not in a
	private place during the session. On our end, we will take reasonable
	steps to ensure your privacy. But it is important for you to make sure
	you find a private place for our session where you will not be
	interrupted. It is also important for you to protect the privacy of our
	session on your cell phone or other devices. You should participate in
	telehealth only while in a room or area where other people are not
	present and cannot overhear the conversation.`,
	'gcf_telehealthTechnology': `Issues related to technology. There are many ways that technology issues
	might impact telehealth. These may include, but are not limited to, the
	possibility that transmission of your medical information could be
	disrupted or distorted by technical failures; the transmission of
	information could be interrupted by unauthorized persons or companies;
	and/or misunderstandings between you and your provider can more easily occur.`,
	'gcf_telehealthCrisisManagement': `Crisis management and intervention. Usually, we will not engage in
	telehealth with clients who are currently in a crisis situation
	requiring high levels of support and intervention. Before engaging in
	telehealth, we will develop an emergency response plan to address
	potential crisis situations that may arise during the course of our telehealth work.`,
	'gcf_telehealthEfficacy': `Efficacy. Most research shows that telehealth is about as effective as
	in-person services. However, some therapists believe that something is
	lost by not being in the same room. For example, there is debate about a
	therapist’s ability to fully understand non-verbal information when working remotely.`,
	'gcf_telehealthElectronic': `Electronic Communications`,
	'gcf_teleheathElectronicComputer': `You may have to have certain computer or cell phone systems to use
	telehealth services. You are solely responsible for any cost to you to
	obtain any necessary equipment, accessories, or software to take part in telehealth.`,
	'gcf_telehealthConfidentiality': 'Confidentiality',
	'gcf_telehealthConfidentialityLegal': `We have a legal and ethical responsibility to make our best efforts to
	protect all communications that are a part of telehealth. However, the
	nature of electronic communications technologies is such that we cannot
	guarantee that our communications will be kept confidential or that other
	people may not gain access to our communications. We will try to use updated
	encryption methods, firewalls, and back-up systems to help keep your
	information private, but there is a risk that our electronic communications
	may be compromised, unsecured, or accessed by others. You should also take
	reasonable steps to ensure the security of our communications (for example,
	only using secure networks for telehealth sessions and having passwords to protect the device you use for telehealth).`,
	'gcf_telehealthConfidentialityException': `The extent of confidentiality and the exceptions to confidentiality that we
	outlined in our Informed Consent still apply in telehealth. By agreeing to
	telehealth, you are agreeing that you understand we do not have full control
	over who hears information that we discuss. You also agree to allow your
	child to conduct the visit in a private space, if possible, or notify the
	clinician if others can hear or see the session when sensitive information
	is being discussed. Please let us know if you have any questions about exceptions to confidentiality.`,
	'gcf_telehealthAppropriateness': 'Appropriateness of Telehealth',
	'gcf_telehealthAppropriatenessForm': `We will let you know if we decide that telehealth is not the most
	appropriate form of treatment for you. We will discuss options for engaging
	in in-person services or referrals to another professional in your location
	who can provide appropriate services. For certain individuals, we ask that
	an adult facilitator be present in the room to assist with technical
	difficulties or keep a
	child on task. Parents/Guardians have the right to withhold or withdraw
	consent to telehealth at any time without affecting the right to the future
	care of treatment. The same level of ethical and professional standards
	apply to telehealth services as they do for in-person service delivery.`,
	'gcf_telehealthEmergencies': 'Emergencies and Technology',
	'gcf_telehealthEmergenciesSession': `During telehealth sessions, assessing and evaluating threats and other
	emergencies can be more difficult when conducting telehealth than in
	traditional in-person therapy. To address some of these difficulties, we
	have an emergency plan for engaging in telehealth services. We will ask you
	to identify an emergency contact person who is near your location and who we
	will contact in the event of a crisis or emergency to assist in addressing
	the situation. Your signature on this document allows us to contact your
	emergency contact person or the State Crisis Hotline at 1-844-534-HOPE
	(4673) as needed during such a crisis or emergency.`,
	'gcf_telehealthEmergenciesSessionInterrupted': `If the session is interrupted for any reason, such as the technological
	connection fails, and you are having an emergency, do not call your provider
	back; instead, call 911, call or text 988 or go to your nearest emergency
	room. Call your telehealth provider back after you have called or obtained
	emergency services.`,
	'gcf_telehealthEmergenciesSessionInterruptedNotEmergency': `If the session is interrupted and you are not having an emergency,
	disconnect from the session and we will wait two (2) minutes and then
	re-contact you via the telehealth platform on which we agreed to conduct
	therapy. If you do not receive a call back within two (2) minutes, then call
	your provider on the phone number we provided you.`,
	'gcf_telehealthEmergenciesTechnological': `If there is a technological failure and we are unable to resume the
	connection, you will only be charged the prorated amount of actual session time.`,
	'gcf_telehealthInstate': 'In-state Services Only',
	'gcf_telehealthInstateChild': `Our clinicians are licensed at the state level. Therefore, we cannot provide
	teleservices to any child who is not in the same state or a Psypact state at the time of the
	session. At the start of your visit, your provider will ask you for the
	address where you are receiving telehealth services in order to confirm that
	you are located in state and to ensure there is an accurate location for you in case of an emergency.`,
	'gcf_telehealthInstateFee': 'Fees',
	'gcf_telehealthInstateFeeRate': `The same fee rates will apply for telehealth as applying for in-person
	services. However, insurance or other managed care providers may not cover
	sessions that are conducted via telecommunication.
	If your insurance, HMO, third-party payor, or other managed care provider does not cover
	electronic telehealth sessions, you will be solely responsible for the
	entire fee of the session. Please contact your insurance company prior to
	our engaging in telehealth sessions in order to determine whether these
	sessions will be covered.`,
	'gcf_telehealthInstateRecord': 'Records',
	'gcf_telehealthInstateRecordAgree': `The evaluation will be recorded by Axis for Autism, LLC. You and your child
	agree that the session will be recorded and reviewed by a licensed
	psychologist. Axis for Autism may use the recordings for training its
	employees. If you do not want your child’s recording used for training
	purposes, please initial in the box below. We will maintain a record of our
	session in the same way we maintain records of in-person sessions in
	accordance with our policies.`,
	'gcf_telehealthAcknowledge': 'I acknowledge that my child will be recorded for diagnostic purposes.',
	'gcf_teleheathAcknowledgeNot': 'I do NOT want my/my child’s recording used for training purposes',
	'gcf_teleheathPhotos': `Recording or taking photos is strictly prohibited by anyone in the
	room due to copyright protection. By signing this consent, you agree to this policy.`,
	'gcf_telehealthFeedback': `The telehealth feedback session between the guardian and psychologist shall
	not be recorded in any way unless agreed to in writing by mutual consent. We
	will maintain a record of our session in the same way we maintain records of
	in-person sessions in accordance with our policies. By accepting telehealth,
	you and your child agree that the session will not be recorded, reproduced,
	or published, that copies of materials will not be made, nor will the content be shared.`,
	'gcf_telehealthHipaa': 'HIPAA/BAA',
	'gcf_telehealthZoom': `I understand that Axis for Autism uses Zoom for HealthCare Video
	Conferencing Software to provide telehealth. The telehealth delivery model
	is being used during the COVID-19 pandemic in numerous states across the
	United States. I understand that Axis for Autism has entered into a Business
	Associate Agreement (“BAA”) with Zoom that will enable HIPAA compliance for
	all telehealth. Pursuant to the BAA, Zoom is responsible for keeping all
	patient information secure and reporting security breaches involving
	personal healthcare information. Zoom does not have access to identified
	health information and Zoom protects and encrypts all audio, video, and screensharing data.`,
	'gcf_telehealthAuthorization': `This consent and authorization are valid until revoked by you in writing.
	Your signature below indicates agreement with its terms and conditions.`,
	'gcf_covid': 'COVID-19 Consent Form',
	'gcf_covidInformation': `This document contains important information about our decision (yours and
	mine) to resume in- person services considering COVID-19. Please read this
	carefully and let the company know if you have any questions. When you sign
	this document, it will be an official agreement between you and the company.`,
	'gcf_covidDecision': 'The Decision to Meet Face-to-Face',
	'gcf_covidDecisionAgreed': `We have agreed to meet in person for a diagnostic evaluation or treatment
	services. If there is a resurgence of the pandemic or if other health
	concerns arise, however, we may require that we reschedule further testing,
	or meet via telehealth. If you have concerns about meeting through
	telehealth, we will talk about it first and try to address any issues. You
	understand that, if we believe it is necessary, we may determine that we
	return to telehealth for everyone’s well-being.`,
	'gcf_covidDecisionAnytime': `If you decide at any time that you would feel safer staying with, or
	returning to, telehealth services, we will respect that decision, as long as
	it is feasible and clinically appropriate. Reimbursement for telehealth
	services, however, is also determined by the insurance companies and
	applicable law, so that is an issue we may also need to discuss.`,
	'gcf_covidRisk': 'Risks of Opting for In-Person Services',
	'gcf_covidRiskUnderstand': `You understand that by coming to the office, you are assuming the risk of
	exposure to the coronavirus (or other public health risks).`,
	'gcf_covidRiskIncrease': `This risk may increase if you travel by public transportation, cab, or ridesharing service.`,
	'gcf_covidRiskExposure': 'Your Responsibility to Minimize Your Exposure',
	'gcf_covidRiskExposureInperson': `To obtain services in person, you agree to take certain precautions that
	will help keep everyone (you, your clinician, and our families, other staff,
	and other patients) safer from exposure, sickness, and possible death. If
	you do not adhere to these safeguards, it may result in our rescheduling to
	a later date. Initial each to indicate that you understand and agree to these actions:`,
	'gcf_covidSafeguard': 'Safeguards',
	'gcf_covidSafeguardAppointment': `You will only keep your in-person appointment if you and your child are symptom-free`,
	'gcf_covidTemperature': `You will take your temperature/child’s temperature before coming to
	each appointment. If it is elevated (99.5 Fahrenheit or more), or if
	you have other symptoms of the coronavirus, you agree to cancel the
	appointment and reschedule. If you wish to cancel for this reason, we won’t charge you any fee.`,
	'gcf_covidResident': `If a resident of your home tests positive for the infection, you
	will immediately let us know, and we will reschedule.`,
	'gcf_covidMask': `While receiving services, neither the child nor the professional
	will have a mask on as facial expressions are important part of the process.`,
	'gcf_covidOrder': `We may change the above precautions if additional local, state, or federal
	orders or guidelines are published. If that happens, we will talk about any necessary changes.`,
	'gcf_covidExposure': `Our Commitment to Minimize Exposure`,
	'gcf_covidExposureSteps': `We have taken steps to reduce the risk of spreading the coronavirus within
	the office and we have posted our efforts through email and in the office.
	Please let us know if you have questions about these efforts.`,
	'gcf_covidSick': 'If You or Your Clinician Are Sick',
	'gcf_covidSickSpread': `You understand that we are committed to keeping everyone safe from the
	spread of this virus. If you show up for an appointment and we believe that
	you/your child have a fever or other symptoms, or believe you/your child
	have been exposed, we will have to require you to leave the office
	immediately. We can follow up with rescheduling services as appropriate.`,
	'gcf_covidPositive': `If we test positive for the coronavirus, we will notify you so that you can take appropriate precautions`,
	'gcf_covidInformed': 'Informed Consent',
	'gcf_covidInformedSuppliments': `This agreement supplements other general informed consent/business
	agreements that we have agreed to.`,
	'gcf_covidSignature': 'Your signature below shows that you agree to these terms and conditions.',
	'gcf_financial': 'Patient Financial Responsibility and Refunds',
	'gcf_financialResponsiblity': 'Patient Financial Responsibility',
	'gcf_financialInsurance': 'Insurance:',
	'gcf_financialUnderstanding': `Guardians/Patients are responsible for understanding their insurance
	policies and benefits. If/when a patient receives Axis for Autism
	services that end up being denied due to lack of benefits or in the
	event a funding agency removes said patient from its active roster in
	the middle of services, the patient or the person(s) legally responsible
	for the patient will reimburse Axis for Autism for all services provided
	that were not reimbursed by the funding agency`,
	'gcf_financialTreatment': `In order to set realistic treatment goals and priorities, it is
	important to evaluate what resources you have available to pay for your
	treatment. If you have a health insurance policy, it will usually
	provide some coverage for mental/behavioral health treatment. Axis for
	Autism will fill out forms and provide you with whatever assistance we
	can in helping you receive the benefits to which you are entitled;
	however, you (not your insurance company) are responsible for full
	payment of your fees. It is important you find out exactly what
	mental/behavioral health services your insurance policy covers.`,
	'gcf_financialSection': `You should carefully read the section in your insurance coverage booklet that describes
	mental/behavioral health services. If you have questions about your
	coverage, call your plan administrator. Axis for Autism will provide you
	with whatever information we can based on our experience and will be
	happy to help you in understanding the information you receive from your
	insurance company. If it is necessary to clear confusion, Axis for
	Autism will be willing to call the company on your behalf.`,
	'gcf_financialRisingCost': `Due to the rising cost of health care, insurance benefits have
	increasingly become more complex. It is difficult to determine exactly
	how much mental/behavioral health coverage is available. Managed health
	care plans such as HMOs and PPOs often require authorization before they
	provide reimbursement for mental/behavioral health services. These plans
	are often limited to short-term treatment approaches designed to work
	out specific problems that interfere with a person’s usual level of
	functioning. It may be necessary to seek approval for more therapy after
	a certain number of sessions. While a lot can be accomplished in
	short-term therapy, some patients feel that they need more services
	after insurance benefits end.`,
	'gcf_financialCompanies': `You should also be aware that most insurance companies require you to
	authorize your clinician to provide them with a clinical diagnosis.
	Sometimes your clinician will have to provide additional clinical
	information such as treatment plans or summaries, or copies of the
	entire record (in rare cases). This information will become a part of
	the insurance company files and will probably be stored in a computer.
	Though all insurance companies claim to keep such information
	confidential, Axis for Autism has no control over what they do with it
	once it is in their hands. In some cases, they may share the information
	with a national medical databank. Your clinician will provide you with a
	copy of any report submitted if you request it.`,
	'gcf_financialInformation': `Once we have all the information about your insurance coverage, we will
	discuss what we can expect to accomplish with the benefits that are
	available and what will happen if they run out before all necessary
	appointments are complete. It is important to remember you always have
	the right to pay for our services yourself to avoid the problems listed
	above, unless it is prohibited by the contract.`,
	'gcf_aba': `All co-insurance and co-payments are due at time of services for ABA
	services and either at the time of scheduling or the day before the
	initial appointment for diagnostics. This will be an estimate of payment
	due given by your insurance but is not a guarantee of full payment. You
	may receive another bill once the claims have finished processing.`,
	'gcf_updated': `Guardians/Patients are responsible to provide Axis for Autism with
	updated insurance documentation every January and whenever insurance information changes.`,
	'gcf_privatePay': 'Private Pay',
	'gcf_privatepayAmount': `When paying privately, the full amount is due at the time of service for
	ABA services (at time of scheduling or the day before the initial appointment for diagnostics).`,
	'gcf_privatePayLate': 'Late Payments',
	'gcf_privatePayNotPaid': `If your account has not been paid for more than 60 days, and
	arrangements for payment have not been agreed upon, Axis for Autism has
	the option of using legal means to secure the payment. This may involve
	hiring a collection agency or going through small claims court. If such
	legal action is necessary, its costs will be included in the claim. In
	most collection situations, the only information we will release
	regarding a patient’s treatment is his/her name, the nature of the service provided, and the amount due.`,
	'gcf_privatePayHardship': `In circumstances of unusual financial hardship, Axis for Autism may be
	willing to negotiate a fee adjustment or payment installment plan.`,
	'gcf_privatePayGeneral': 'General',
	'gcf_privatePayCredit': 'Program fees are payable by patients via cash, check, or credit card.',
	'gcf_professionalFee': 'Professional Fees',
	'gcf_professionalHourly': `Axis for Autism will charge an hourly fee for other professional
	services you might need, though we will break down the cost if your
	clinician works for periods of less than one hour. Other services
	include report writing, telephone conversations lasting longer than 30
	minutes, attendance at meetings with other professionals you have
	authorized, preparation of records or treatment summaries, and the time
	spent performing any other services you request of your clinician. If
	you become involved in legal proceedings that require our participation,
	you will be expected to pay for your clinician’s professional time, even
	if they are called to testify by another party. Because of the
	difficulty of legal involvement, Axis for Autism charges an hourly fee
	for preparation and attendance at legal proceedings. Please contact the
	office at 602-888-8882 for current rates.`,
	'gcf_patientRefund': 'Patient Refunds',
	'gcf_patientRefundOverpay': `If an overpayment is made, refunds will be issued via check`,
	'gcf_patientRefundIndividual': `Individual refund requests should be made by calling our office at 602-888-8882 ext. 720. Requests are reviewed on a case-by-case basis.`,
	'gcf_patientRefundApproved': `Once approved, refunds are issued via an Axis for Autism agency check,
	for payments made via cash or check or, for credit-card payments,
	refunds are credited to the card used at the time of payment.`,
	'gcf_patientRefundReview': `The refund review process, including issuing approved refunds, is usually completed within 14 days of the request.`,
	'gcf_paymentJoint': 'Payment in Cases of Joint Custody',
	'gcf_paymentJointDispute': `Our office does not get involved with domestic disputes and custody
	issues. Our policy is to obtain payment at the time of service from the
	guardian bringing the child to the office. The person who the patient
	resides with is responsible for any balances due upon receipt of a statement.`,
	'gcf_guardian': 'Client/Guardian Grievance Policy',
	'gcf_guardianDissatisfaction': `Should an individual wish to express dissatisfaction or file a concern
	regarding Axis for Autism services or personnel, a grievance may be filed via a grievance form. This
	grievance form can be obtained via the portal, or requested through a phone call, a letter, or
	email (feedback(at)axisforautism.com). This form may be used to express any
	concerns regarding diagnostic and treatment services at Axis for Autism.
	Following a concern or grievance, Axis for Autism will complete the following steps:`,
	'gcf_guardianDissatisfactionVerbally': `Axis for Autism will acknowledge the receipt of the grievance either
	verbally or in writing, based on the form of submission, within 5 business days.`,
	'gcf_guardianDissatisfactionSupervisor': `All concerns submitted to the administrative team will be summarized and
	forwarded to the appropriate departmental supervisor(s) for further
	review if a resolution is not achieved at the initial time the concern
	was presented. The supervisor may schedule a telephonic meeting with the
	complainant to gather more information or to further the investigation.`,
	'gcf_guardianDissatisfactionReached': `When a resolution has been reached, an Axis for Autism partner will
	communicate a summary of the outcome of the presented concern verbally or in writing to the complainant.`,
	'gcf_concerns': `Concerns related to abuse, neglect, the injury of patients or patient family
	members/caregivers on Axis for Autism property or during in-home/community
	sessions or any ethical concerns with treatment will be addressed within 24 business hours of the receipt of the concern.`,
	'gcf_concernsResolve': `Axis for Autism partners will attempt to resolve concerns within their
	purview upon receipt of the concern. If the concern is resolved to your
	satisfaction, the resolution will be documented, and grievance closed.`,
	'gcf_finalDecision': `Axis for Autism will ensure that partners that make the final decision on
	grievances are not involved in a previous level of review or decision making.`,
	'gcf_manner': `Axis for Autism is committed to responding to patient and guardian concerns
	in a timely and thorough manner and will take any necessary action to meet
	the needs of the patient, as appropriate, as well as meet any quality, regulatory or other recognized standards.`,
	'gcf_grievanceProcedure': 'I have read and understand the above grievance procedures.',
	'gcf_signatureBelow': `Your signature below indicates that you have read the information in this document and agree to abide by its terms.`,
	'self': 'Self',
	'spouse': 'Spouse',
	'child': 'Child',
	'doctor_type': 'Select Doctor Type',
	'pediatrician': 'Pediatrician',
	'developmental_pediatrician': 'Developmental Pediatrician',
	'psychiatrist': 'Psychiatrist',
	'counselor': 'Counselor',
	'occupational_therapist': 'Occupational Therapist',
	'speech_therapist': 'Speech Therapist',
	'roi_include_this_info': 'Include this school on the Release of Information consent.',
	'choose_file': 'Choose File',

	//https://seacole.io/patients/appointment/intake/thankyou-intake-questionnaire-complete
	'ty_for_completing_intake': 'Thank you for completing the Intake Questionnaire!',
	'what_s_next': `What's next`,
	'review_and_sign_forms': 'Please review and sign the four notice and consent forms:',
	'general_consent': 'General Consent',
	'consent_for_clinical_services': 'Consent for Clinical Services',
	'notice_of_privacy_practices': 'Notice of Privacy Practices',
	'notice_of_health_info_practices': 'Notice of Health Information Practices',
	'family_court_orders_note': 'Important note: Family court orders may require each parent or legal guardian to consent to this type of evaluation. You may be asked to provide contact information for additional parents or legal guardians who have legal decision-making authority for this patient.',
	'contactUsMessage': 'If you have any questions or concerns, prior to scheduling, please contact us at (602) 888-8882 or email us at',
	'begin_consents_message': 'To begin the Consents, please select the “Continue” button below.',
	'continue': 'Continue',

	// https://seacole.io/patients/appointment/intake/thankyou-consents-complete

	'ty_for_completing_intake_process': 'Thank you for completing the Intake Process!',
	'one_of_care_coordinator_will_contact': `One of our Patient Care Coordinators will contact you within 7 business days to schedule an Assessment. During your appointment, the testing process will last approximately 45 minutes but please expect to be in the office for a total of 60-90 minutes. The additional time will allow for post assessment questionnaires or discussion and scheduling the next appointment. Please plan accordingly.`,
	'care_coordinator_will_discuss': `The Patient Care Coordinator will discuss any further required information for the Assessment with you during the scheduling process.`,
	'start_another_intake_question': 'Do you need to start the intake process for another patient?',

	//https://seacole.io/patients/appointment/intake/release-of-information-form

	'roi_telephone': 'Phone Number',
	'name_of_patient': 'Name of Patient',
	'patient_dob': 'Patient Date of Birth',
	'ia_name_of_parent_guardian_auth': 'If Applicable, Name of Parent/Guardian Providing Authorization',
	'auth_to_release_info': `AUTHORIZATION TO RELEASE/EXCHANGE PROTECTED HEALTH INFORMATION (PHI) to Release/Exchange Protected Health Information (PHI)`,
	'i_hereby_auth_axis': 'I hereby authorize Axis for Autism, LLC to release/exchange the following confidential health information (for the purpose of continuity of care), related to the client named above via fax, phone, e-mail, or in person. With this consent, I understand that this means that confidential documents may be sent over the internet and that the internet is not always completely safeguarded and Protected Health Information could possibly be obtained by unauthorized users. I understand that this consent may be withdrawn at any time by notifying Axis for Autism, in writing, at the following address: 1645 E. Missouri Ave, Suite #310, Phoenix, AZ 85016.',
	'release_exchange_to': 'Release/Exchange to:',
	'name_add_phone_fax_email': 'Name/Address/Phone/Fax/Email:',
	'name_contact_add_phone_fax_email': 'Name/Contact Name/Address/Phone/Fax/Email:',
	'office': 'Office',
	'roi_speech_therapist': 'Speech Therapist',
	'i_hereby': 'I hereby',
	'authorize': ' Authorize ',
	'do_not_authorize': ' Do Not Authorize',
	'release_exchange_auth_quest':`the release and/or exchange, mutual use, and/or disclosure of
	the information indicated above, for these specified purpose(s), between Axis for
	Autism
	and the agencies/professionals listed above.`,
	'authorization_expires': 'This authorization expires',
	'on': ' On',
	'one_year_from_date': ' One year from the date signed',
	'upon_axis_receipt': ` Upon Axis for Autism's receipt of a written
	request
	to revoke authorization (at the address below)`,
	'name_of_parent_guardian_signing': 'Name of Parent/Legal Guardian or Adult Patient Signing',
	'date': 'Date',

	//https://seacole.io/patients/appointment/intake/consent/InsurancePreAuth
	'insurancePreauth': 'Your insurance requires pre-authorization',
	'ins_care_coordinator': 'Your insurance provider requires pre-authorization. A patient care coordinator will be reaching out to you within the next 48 hours to discuss the process and obtain medical records. If you have any questions please feel free to call our office at 602-888-8882',

	'setup': 'Let\'s get you setup!',
	'contact_information_below': 'Please confirm or enter contact information below ',
	'completing_this_form': 'for the person completing this form.',
	'for_the_account_owner': 'This information is for the account owner. Patient information will be collected on another page.',
	'your_first_name': 'Your First Name',
	'your_last_name': 'Your Last Name',
	'your_email': 'Your Email Adress',
	'your_phone': 'Your Phone Number',
	'your_preferred_language': 'Your Preferred Language',
	'other_language': 'Enter Other Language',
	'sign_up': 'Sign Up',
	'something_not_right': 'Oops, something\'s not right!',
	'okay': 'OKAY',
	'invite_invalid': 'The invitation has expired or has already been used. Please contact administrator or call our office at 602-888-8882.',
	'patient_exists': 'A user with this email or phone already exists.',
	'check_phone_or_email': 'Please check phone or email',
	'code_below': 'Enter the code below',
	'code': 'Code',
	'invalid_code': 'Invalid Code',
	'enter_correct_code': 'Please make sure the correct code is used otherwise your account will be locked.',
	'code_required': 'Code is required',
	'must_be_6_digits': 'Must be 6 digits',
	'login': 'login',
	'verifcation_code': 'Your verification code',
	'first_name_required': 'Your first name is required',
	'last_name_required': 'Your last name is required',
	'email_required': 'Your email is required',
	'phone_required': 'Your phone is required',
	'terms_required': 'You must agree to the terms of service',
	'invalid_email': 'Invalid Email',
	'i_agree': 'I agree to the',
	'terms_of_service': 'Terms of Service',
	'email_or_phone': 'Email',
	'account_inactive': 'Your account is inactive. Please contact administrator or call our office at 602-888-8882.',
	'email_or_phone_required': 'Email is required',
	'phone_invalid': 'Phone is invalid',
	'loginError': 'Login Error',
	'loginErrorText': 'We couldn\'t find an account with the email you entered. This might be because:',
	'accountNotRegistererd': 'Your account insn\'t registered yet.',
	'pleaseContactTeam': 'If you are new to Axis for Autism, please contact our team at (602) 888-8882 to register.',
	'typeOrDifferentEmail': 'There\'s is a typo or you may have registerd a different email address.',
	'goPrevious': 'Go back to the previous screen and double check your email address.',
	'furtherAssistance': 'If you need further assistance, please contact us at (602) 888-8882.',
	'preferred_language_required': 'Preferred language is required'
};
