import { createWebHistory, createRouter } from 'vue-router';
import lib from '!/library-web/lib';
import utils from '@/utils';

const routes = [
	{
		path: '/',
		component: () => import('@/pages/_Console.vue'),
		children: [
			{
				name:'Home',
				path: '/',
				component: () => import('@/pages/Home.vue'),
			},
			{
				name:'Splash',
				path: '/splash',
				component: () => import('@/pages/Splash.vue'),
			},
			{
				path: '/profile',
				component: () => import('@/pages/profile/Layout.vue'),
				children: [
					{
						path: '/profile',
						component: () => import('@/pages/profile/Profile.vue'),
						children: [
							{
								path: '/profile',
								component: () => import('@/pages/profile/Account.vue'),
							},
						],
					},
				],
			},
			{
				name: 'testpage',
				path: '/test-page',
				component: () => import('@/pages/TestPage.vue'),
			},
			{
				name: 'appointments',
				path: '/appointments',
				component: () => import('@/pages/Appointments.vue'),
			},
			{
				name: 'intakes-index',
				path: '/intakes',
				component: () => import('@/pages/intake/Index.vue'),
			},
			{
				name: 'messages',
				path: '/messages',
				component: () => import('@/pages/Messages.vue'),
			},
			{
				name: 'patients',
				path: '/patients',
				component: () => import('@/pages/Patients/Index.vue'),
			},
			{
				name: 'patient-add',
				path: '/patient-add/:id',
				component: () => import('@/pages/Patients/Add.vue'),
			},
			{
				name: 'my-tasks',
				path: '/my-tasks',
				component: () => import('@/pages/Mytasks.vue'),
			},
			{
				name: 'bills',
				path: '/bills',
				component: () => import('@/pages/Billings.vue'),
			},
			{
				name: 'my-records',
				path: '/my-records',
				component: () => import('@/pages/Myrecords.vue'),
			},
			{
				name: 'my-library',
				path: '/my-library',
				component: () => import('@/pages/Mylibrary.vue'),
			},
			{
				name: 'my-membership',
				path: '/my-membership',
				component: () => import('@/pages/Mymembership.vue'),
			},
			{
				name: 'invoices-list',
				path: '/bills/invoices',
				component: () => import('@/components/InvoiceList.vue'),
			},
			// {
			// 	name: 'invoice-details',
			// 	path: '/bills/invoice/:id',
			// 	component: () => import('@/components/InvoiceDetails.vue'),
			// },
			{
				name: 'special-needs-verification',
				path: '/appointment/intake/special-needs-verification',
				component: () => import('@/pages/SpecialNeeds.vue'),
				meta: { percentage: 60 }
			},
			{
				name: 'special-needs-page',
				path: '/special-needs-page',
				component: () => import('@/pages/SpecialNeedsPage.vue'),
			},
			{
				name: 'intake-step-1',
				path: '/appointment/intake/add-new/:id',
				component: () => import('@/pages/AppointmentType.vue'),
			},
			{
				name: 'incomplete',
				path: '/appointment/intake/incomplete/:id',
				component: () => import('@/pages/intake/Incomplete.vue'),
			},
			{
				name: 'route-agreement',
				path: '/appointment/intake/agreement/:id',
				component: () => import('@/pages/intake/Agreement.vue'),
			},
			{
				name: 'form-home',
				path: '/appointment/intake/form-home',
				component: () => import('@/pages/intake/completingForm/Home.vue'),
				meta: { percentage: 0 }
			},
			{
				name: 'form-demographic',
				path: '/appointment/intake/form-demographic',
				component: () => import('@/pages/intake/completingForm/Demographic.vue'),
				meta: { percentage: 2 }
			},
			{
				name: 'form-demographic-adult',
				path: '/appointment/intake/form-demographic-adult',
				component: () => import('@/pages/intake/completingForm/Adult.vue'),
				meta: { percentage: 2 }
			},
			{
				name: 'form-list',
				path: '/appointment/intake/form-list',
				component: () => import('@/pages/intake/completingForm/List.vue'),
			},
			{
				name: 'insurance-verify',
				path: '/appointment/intake/insurance-verify',
				component: () => import('@/pages/intake/insurance/InsuranceVerify.vue'),
			},
			{
				name: 'insurance-out-of-network',
				path: '/appointment/intake/insurance-out-of-network',
				component: () => import('@/pages/intake/insurance/InsuranceOutOfNetwork.vue'),
			},
			{
				name: 'welcome-after-insurance-out-of-network',
				path: '/appointment/intake/welcome',
				component: () => import('@/pages/intake/insurance/WelcomeAfterInsuranceOutOfNetwork.vue'),
			},
			{
				name: 'insurance-photo',
				path: '/appointment/intake/insurance-photo',
				component: () => import('@/pages/intake/insurance/InsurancePhoto.vue'),
			},
			{
				name: 'insurance-details',
				path: '/appointment/intake/insurance-details',
				component: () => import('@/pages/intake/insurance/InsuranceDetails.vue'),
				meta: { percentage: 3 }
			},
			{
				name: 'secondary-insurance-details',
				path: '/appointment/intake/secondary-insurance-details',
				component: () => import('@/pages/intake/insurance/SecondaryInsurance.vue'),
				meta: { percentage: 3 }
			},
			{
				name: 'authority-parent',
				path: '/appointment/intake/authority-parent',
				component: () => import('@/pages/intake/parents/AuthorityParent.vue'),
				meta: { percentage: 66 }
			},
			{
				name: 'insurance-preauth',
				path: '/appointment/intake/insurance-preauth',
				component: () => import('@/pages/intake/insurance/InsurancePreAuth.vue'),
			},
			{
				name: 'insurance-privatepay',
				path: '/appointment/intake/insurance-privatepay',
				component: () => import('@/pages/intake/insurance/InsurancePrivatepay.vue'),
			},
			{
				name: 'insurance-esa-funds',
				path: '/appointment/intake/insurance-esa-funds',
				component: () => import('@/pages/intake/insurance/InsuranceESAFunds.vue'),
				meta: { percentage: 3 }
			},
			{
				name: 'insurance-gentry-scholorship',
				path: '/appointment/intake/insurance-gentry-scholorship',
				component: () => import('@/pages/intake/insurance/InsuranceGentryScholarship.vue'),
			},
			{
				name: 'behavioral-form1', // page removed from intake process
				path: '/appointment/intake/behavioral-form1',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation1.vue'),
			},
			{
				name: 'behavioral-form2',
				path: '/appointment/intake/behavioral-form2',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation2.vue'),
				meta: { percentage: 4 }
			},
			{
				name: 'behavioral-form3',
				path: '/appointment/intake/behavioral-form3',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation3.vue'),
				meta: { percentage: 5 }
			},
			{
				name: 'behavioral-form4',
				path: '/appointment/intake/behavioral-form4',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation4.vue'),
				meta: { percentage: 6 }
			},
			{
				name: 'care-coordinator',
				path: '/appointment/intake/care-coordinator',
				component: () => import('@/pages/intake/consent/CareCordinator.vue'),
			},
			{
				name: 'care-coordinator-2',
				path: '/appointment/intake/care-coordinator-2',
				component: () => import('@/pages/intake/consent/CareCordinator2.vue'),
			},
			{
				name: 'care-coordinator-3',
				path: '/appointment/intake/care-coordinator-3',
				component: () => import('@/pages/intake/consent/CareCordinator3.vue'),
			},
			{
				name: 'adult-patient-notification',
				path: '/adult-patient-notification',
				component: () => import('@/pages/intake/consent/IsAdultNotification.vue'),
			},
			{
				name: 'insurance-gentry-scholarship-stop',
				path: '/appointment/intake/insurance-gentry-scholarship-stop',
				component: () => import('@/pages/intake/insurance/InsuranceGentryScholarshipStop.vue'),
			},
			{
				name: 'partner-consent-info',
				path: '/appointment/intake/partner-consent-info',
				component: () => import('@/pages/intake/consent/partnerConsentInfo.vue'),
			},
			{
				name: 'attestation',
				path: '/appointment/intake/attestation',
				component: () => import('@/pages/intake/Attestation.vue'),
				meta: { percentage: 64 }
			},
			{
				name: 'upload-court-order',
				path: '/appointment/intake/upload-court-order',
				component: () => import('@/pages/intake/consent/UploadCourtOrder.vue'),
				meta: { percentage: 66 }
			},
			{
				name: 'behavioral-form5',
				path: '/appointment/intake/behavioral-form5',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation5.vue'),
				meta: { percentage: 7 }
			},
			{
				name: 'behavioral-form6',
				path: '/appointment/intake/behavioral-form6',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation6.vue'),
				meta: { percentage: 8 }
			},
			{
				name: 'behavioral-form7',
				path: '/appointment/intake/behavioral-form7',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation7.vue'),
				meta: { percentage: 9 }
			},
			{
				name: 'behavioral-form8',
				path: '/appointment/intake/behavioral-form8',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation8.vue'),
				meta: { percentage: 10 }
			},
			{
				name: 'behavioral-form9',
				path: '/appointment/intake/behavioral-form9',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation9.vue'),
				meta: { percentage: 11 }
			},
			{
				name: 'behavioral-form10',
				path: '/appointment/intake/behavioral-form10',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation10.vue'),
				meta: { percentage: 12 }
			},
			{
				name: 'behavioral-form11',
				path: '/appointment/intake/behavioral-form11',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation11.vue'),
				meta: { percentage: 13 }
			},
			{
				name: 'behavioral-form12',
				path: '/appointment/intake/behavioral-form12',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation12.vue'),
				meta: { percentage: 14 }
			},
			{
				name: 'behavioral-form13',
				path: '/appointment/intake/behavioral-form13',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation13.vue'),
				meta: { percentage: 15 }
			},
			{
				name: 'behavioral-form14',
				path: '/appointment/intake/behavioral-form14',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation14.vue'),
				meta: { percentage: 16 }
			},
			{
				name: 'behavioral-form15',
				path: '/appointment/intake/behavioral-form15',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation15.vue'),
				meta: { percentage: 17 }
			},
			{
				name: 'behavioral-form16',
				path: '/appointment/intake/behavioral-form16',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation16.vue'),
				meta: { percentage: 18 }
			},
			{
				name: 'behavioral-form17',
				path: '/appointment/intake/behavioral-form17',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation17.vue'),
				meta: { percentage: 20 }
			},
			{
				name: 'behavioral-form18',
				path: '/appointment/intake/behavioral-form18',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation18.vue'),
				meta: { percentage: 22 }
			},
			{
				name: 'behavioral-form19',
				path: '/appointment/intake/behavioral-form19',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation19.vue'),
			},
			{
				name: 'behavioral-form20',
				path: '/appointment/intake/behavioral-form20',
				component: () => import('@/pages/intake/behavioral/BehavioralInformation20.vue'),
			},
			{
				name: 'physiological-form1',
				path: '/appointment/intake/physiological-form1',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation1.vue'),
				meta: { percentage: 24 }
			},
			{
				name: 'physiological-form2',
				path: '/appointment/intake/physiological-form2',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation2.vue'),
				meta: { percentage: 26 }
			},
			{
				name: 'physiological-form3',
				path: '/appointment/intake/physiological-form3',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation3.vue'),
				meta: { percentage: 30 }
			},
			{
				name: 'physiological-form4',
				path: '/appointment/intake/physiological-form4',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation4.vue'),
				meta: { percentage: 32 }
			},
			{
				name: 'physiological-form5',
				path: '/appointment/intake/physiological-form5',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation5.vue'),
				meta: { percentage: 34 }
			},
			{
				name: 'physiological-form6',
				path: '/appointment/intake/physiological-form6',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation6.vue'),
				meta: { percentage: 28 }
			},
			{
				name: 'physiological-form7',
				path: '/appointment/intake/physiological-form7',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation7.vue'),
				meta: { percentage: 36 }
			},
			{
				name: 'physiological-form8',
				path: '/appointment/intake/physiological-form8',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation8.vue'),
				meta: { percentage: 38 }
			},
			{
				name: 'physiological-form9',
				path: '/appointment/intake/physiological-form9',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation9.vue'),
				meta: { percentage: 40 }
			},
			{
				name: 'physiological-form10',
				path: '/appointment/intake/physiological-form10',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation10.vue'),
				meta: { percentage: 42 }
			},
			{
				name: 'physiological-form11',
				path: '/appointment/intake/physiological-form11',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation11.vue'),
				meta: { percentage: 44 }
			},
			{
				name: 'physiological-form12',
				path: '/appointment/intake/physiological-form12',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation12.vue'),
			},
			{
				name: 'physiological-form13',
				path: '/appointment/intake/physiological-form13',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation13.vue'),
			},
			{
				name: 'physiological-form14',
				path: '/appointment/intake/physiological-form14',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation14.vue'),
				meta: { percentage: 46 }
			},
			{
				name: 'physiological-form15',
				path: '/appointment/intake/physiological-form15',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation15.vue'),
				meta: { percentage: 48 }
			},
			{
				name: 'physiological-form16',
				path: '/appointment/intake/physiological-form16',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation16.vue'),
				meta: { percentage: 50 }
			},
			{
				name: 'physiological-form17',
				path: '/appointment/intake/physiological-form17',
				component: () => import('@/pages/intake/physiological/PhysiologicalInformation17.vue'),
			},
			{
				name: 'school-history-form1',
				path: '/appointment/intake/school-history-form1',
				component: () => import('@/pages/intake/schoolhistory/SchoolInformation1.vue'),
				meta: { percentage: 52 }
			},
			{
				name: 'school-history-form2',
				path: '/appointment/intake/school-history-form2',
				component: () => import('@/pages/intake/schoolhistory/SchoolInformation2.vue'),
				meta: { percentage: 54 }
			},
			{
				name: 'school-history-form3',
				path: '/appointment/intake/school-history-form3',
				component: () => import('@/pages/intake/schoolhistory/SchoolInformation3.vue'),
			},
			{
				name: 'school-history-form4',
				path: '/appointment/intake/school-history-form4',
				component: () => import('@/pages/intake/schoolhistory/SchoolInformation4.vue'),
			},
			{
				name: 'school-history-form5',
				path: '/appointment/intake/school-history-form5',
				component: () => import('@/pages/intake/schoolhistory/SchoolInformation5.vue'),
			},
			{
				name: 'school-history-form6',
				path: '/appointment/intake/school-history-form6',
				component: () => import('@/pages/intake/schoolhistory/SchoolInformation6.vue'),
				meta: { percentage: 56 }
			},
			{
				name: 'school-history-form7',
				path: '/appointment/intake/school-history-form7',
				component: () => import('@/pages/intake/schoolhistory/SchoolInformation7.vue'),
			},
			{
				name: 'school-history-form8',
				path: '/appointment/intake/school-history-form8',
				component: () => import('@/pages/intake/schoolhistory/SchoolInformation8.vue'),
			},
			{
				name: 'school-history-form9',
				path: '/appointment/intake/school-history-form9',
				component: () => import('@/pages/intake/schoolhistory/SchoolInformation9.vue'),
			},
			{
				name: 'release-of-information',
				path: '/appointment/intake/release-of-information-form',
				component: () => import('@/pages/intake/ReleaseOfInformation.vue'),
				meta: { percentage: 62 }
			},
			{
				name: 'patient-age',
				path: '/appointment/intake/patient-age',
				component: () => import('@/pages/intake/patient/PatientAge.vue'),
			},
			{
				name: 'patient-form1',
				path: '/appointment/intake/patient-form1',
				component: () => import('@/pages/intake/patient/PatientInformation1.vue'),
			},
			{
				name: 'patient-form2',
				path: '/appointment/intake/patient-form2',
				component: () => import('@/pages/intake/patient/PatientInformation2.vue'),
			},
			{
				name: 'patinet-form3',
				path: '/appointment/intake/patient-form3',
				component: () => import('@/pages/intake/patient/PatientInformation3.vue'),
			},
			{
				name: 'patinet-form4',
				path: '/appointment/intake/patient-form4',
				component: () => import('@/pages/intake/patient/PatientInformation4.vue'),
			},
			{
				name: 'physician',
				path: '/appointment/intake/physician',
				component: () => import('@/pages/intake/Physician.vue'),
			},
			{
				name: 'reason',
				path: '/appointment/intake/reason',
				component: () => import('@/pages/intake/Reason.vue'),
			},
			{
				name: 'parent-form1',
				path: '/appointment/intake/parent-form1',
				component: () => import('@/pages/intake/parents/Parent1.vue'),
			},
			{
				name: 'parent-form2',
				path: '/appointment/intake/parent-form2',
				component: () => import('@/pages/intake/parents/Parent2.vue'),
			},
			{
				name: 'birth-form',
				path: '/appointment/intake/birth-form',
				component: () => import('@/pages/intake/BirthInformation.vue'),
			},
			{
				name: 'family-form',
				path: '/appointment/intake/family-form',
				component: () => import('@/pages/intake/FamilyInformation.vue'),
			},
			{
				name: 'growth-progress-form1',
				path: '/appointment/intake/growth-progress-form1',
				component: () => import('@/pages/intake/gp/GrowthProgress1.vue'),
			},
			{
				name: 'growth-progress-form2',
				path: '/appointment/intake/growth-progress-form2',
				component: () => import('@/pages/intake/gp/GrowthProgress2.vue'),
			},
			{
				name: 'diet',
				path: '/appointment/intake/diet',
				component: () => import('@/pages/intake/Diet.vue'),
			},
			{
				name: 'behaviour-form1',
				path: '/appointment/intake/behaviour-form1',
				component: () => import('@/pages/intake/behaviour/Behaviour1.vue'),
			},
			{
				name: 'behaviour-form2',
				path: '/appointment/intake/behaviour-form2',
				component: () => import('@/pages/intake/behaviour/Behaviour2.vue'),
			},
			{
				name: 'school-form',
				path: '/appointment/intake/school-form',
				component: () => import('@/pages/intake/School.vue'),
			},
			{
				name: 'tellus',
				path: '/appointment/intake/tellus',
				component: () => import('@/pages/intake/Tellus.vue'),
			},
			{
				name: 'upload',
				path: '/appointment/intake/upload',
				component: () => import('@/pages/intake/UploadRecords.vue'),
				meta: { percentage: 58 }
			},
			{
				name: 'modality',
				path: '/appointment/intake/modality',
				component: () => import('@/pages/intake/Modality.vue'),
			},
			{
				name: 'provider-list-form',
				path: '/appointment/intake/provider-list-form',
				component: () => import('@/pages/intake/doctors/ProviderList.vue'),
			},
			{
				name: 'provider-finder',
				path: '/appointment/intake/provider-finder',
				component: () => import('@/pages/intake/doctors/ProviderFinder.vue'),
			},
			{
				name: 'someone-will-reach-out',
				path: '/appointment/intake/someone-will-reach-out',
				component: () => import('@/pages/intake/SomeoneWillReachOut.vue'),
			},
			{
				name: 'provider-filter',
				path: '/appointment/intake/provider-filter',
				component: () => import('@/pages/intake/doctors/ProviderFilter.vue'),
			},
			{
				name: 'provider-specific-time',
				path: '/appointment/intake/provider-specific-time/:provider_id',
				component: () => import('@/pages/intake/doctors/ProviderSpecificTime.vue'),
			},
			{
				name: 'care-coordinator-contact',
				path: '/appointment/intake/care-coordinator-contact',
				component: () => import('@/pages/intake/doctors/CareCoordinatorContact.vue'),
			},
			{
				name: 'payment-form',
				path: '/appointment/intake/payment-form',
				component: () => import('@/pages/intake/payments/PaymentForm.vue'),
			},
			{
				name: 'checkout',
				path: '/appointment/intake/checkout-form/:intake',
				component: () => import('@/pages/intake/payments/CheckoutForm.vue'),
			},
			{
				name: 'single-checkout',
				path: '/bills/single-checkout/:invoice_id',
				component: () => import('@/pages/Checkout.vue'),
			},
			{
				name: 'payment-covered-form',
				path: '/appointment/intake/payment-covered-form',
				component: () => import('@/pages/intake/payments/PaymentOptionsCovered.vue'),
			},
			{
				name: 'payment-not-covered-form',
				path: '/appointment/intake/payment-not-covered-form',
				component: () => import('@/pages/intake/payments/PaymentOptionsNotCovered.vue'),
			},
			{
				name: 'payment-cancelled',
				path: '/appointment/intake/payment-cancelled',
				component: () => import('@/pages/intake/payments/PaymentCancel.vue'),
			},
			{
				name: 'appointment-invoice',
				path: '/invoice/:invoice_id',
				component: () => import('@/pages/invoice/invoice.vue'),
			},
			{
				name: 'contact-consent',
				path: '/appointment/intake/contact-consent',
				component: () => import('@/pages/intake/consent/ContactConsent.vue'),
			},
			{
				name: 'consent-general-form',
				path: '/appointment/intake/consent-general-form',
				component: () => import('@/pages/intake/consent/ConsentGeneralForm.vue'),
				meta: { percentage: 68 }
			},
			{
				name: 'consent-clinical-services',
				path: '/appointment/intake/consent-clinical-services',
				component: () => import('@/pages/intake/consent/ConsentClinicalServicesForm.vue'),
				meta: { percentage: 70 }
			},
			{
				name: 'notice-privacy-practices',
				path: '/appointment/intake/notice-privacy-practices',
				component: () => import('@/pages/intake/consent/NoticePrivacyPractices.vue'),
				meta: { percentage: 72 }
			},
			{
				name: 'notice-health-information-practices',
				path: '/appointment/intake/notice-health-information-practices',
				component: () => import('@/pages/intake/consent/NoticeHealthInformationPractices.vue'),
				meta: { percentage: 75 }
			},
			{
				name: 'completing-intake-transition-page',
				path: '/appointment/intake/thankyou-intake-questionnaire-complete',
				component: () => import('@/pages/intake/transitionPages/IntakeCompleteTransition.vue'),
				meta: { percentage: 65 }
			},
			{
				name: 'completing-consents-transition-page',
				path: '/appointment/intake/thankyou-consents-complete',
				component: () => import('@/pages/intake/transitionPages/ConsentsCompleteTransition.vue'),
			},
			{
				name: 'authorization-release-information',
				path: '/appointment/intake/authorization-release-information',
				component: () => import('@/pages/intake/consent/AuthorizationReleaseInformation.vue'),
			},
			{
				name: 'all-set',
				path: '/appointment/intake/all-set',
				component: () => import('@/pages/intake/Allset.vue'),
			},
			{
				name: 'bsh1',
				path: '/appointment/intake/bsh-1',
				component: () => import('@/pages/intake/bsh/Home.vue'),
			},
			{
				name: 'bsh2',
				path: '/appointment/intake/bsh-2',
				component: () => import('@/pages/intake/bsh/BSH2.vue'),
			},
			{
				name: 'provider-finder-reschedule',
				path: '/reschedule/:id/provider-finder',
				component: () => import('@/pages/rescheduleAppointment/ProviderFinder_Reschedule.vue'),
			},
			{
				name: 'provider-list-reschedule',
				path: '/reschedule/:id/provider-list',
				component: () => import('@/pages/rescheduleAppointment/ProviderList_Reschedule.vue'),
			},
			{
				name: 'provider-specific-time-reschedule',
				path: '/reschedule/:id/provider-specific-time/:provider_id',
				component: () => import('@/pages/rescheduleAppointment/ProviderSpecificTime_Reschedule.vue'),
			},
		],
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/pages/auth/Index.vue'),
		children: [
			{
				path: '/login',
				component: () => import('@/pages/auth/Login.vue'),
				meta: {
					guest: true,
				},
			},
			{
				path: '/logout',
				component: () => import('@/pages/auth/Logout.vue'),
				meta: {
					guest: true,
				},
			},
			{
				path: '/mfa',
				component: () => import('@/pages/auth/MFA.vue'),
				meta: {
					guest: true,
				},
			},
			
			{
				path: '/impersonate',
				component: () => import('@/pages/auth/Impersonate.vue'),
			},
			{
				path: '/blocked',
				component: () => import('@/pages/auth/Blocked.vue'),
				meta: {
					guest: true,
				},
			},
			{
				path: '/new_user',
				component: () => import('@/pages/auth/New_User.vue'),
				meta: {
					guest: true,
				},
			},
			{
				path: '/login-error',
				component: () => import('@/pages/auth/LoginError.vue'),
				meta: {
					guest: true,
				},
			}
		],
		meta: {
			guest: true,
		},
	},
	
	{
		path: '/not_supported',
		component: () => import('@/pages/Not_Supported.vue'),
		meta: {
			guest: true,
		},
	},

	{
		name: 'partner-consent-link',
		path: '/partner-consent-link/:intake_id/:patient_id/:partner_initials',
		component: () => import('@/pages/intake/consent/PartnerConsent.vue'),
		meta:{
			partnerGuest:true
		}
	},

	{
		name: 'approved-consent',
		path: '/approved-consent/:intake_id/:patient_id',
		component: () => import('@/pages/intake/consent/ApprovedConsent.vue'),
		meta:{
			partnerGuest:true
		}
	},
];

const router = createRouter({
	history: createWebHistory('/patients/'),
	routes,
	scrollBehavior() {
		return { top: 0, left: 0 }
	}
});

router.beforeEach(async (to, from, next) => {
	if(to.fullPath.includes('partner-consent-link')){
		return next()
	}
	if (!to.meta) {
		next();
	} else {
		if (to.matched.some((record) => record.meta.guest)) {
			next();
		} else {
			if (utils.auth.isAuthenticated()) {
				if (to.meta.authorize) {
					let userRoles = await utils.auth.getUserRoles();
					if (to.meta.authorize.some((record) => userRoles.includes(record))) {
						next();
					} else {
						next('/');
					}
				} else {
					next();
				}
			} else {
			
				next(`/login?r=${encodeURIComponent(to.fullPath)}`);
			}
		}
	}
});

router.beforeResolve((to, from, next) => {
	lib.progress.start();
	
	next();
});

router.afterEach(() => {
	if (utils.auth.isAuthenticated()) {
		lib.emitter.emit('route_change');
	}
	lib.progress.stop();
});

export default router;
